import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const YSSwitchPlain = ({ isChecked, onClick, leftValue, rightValue }) => {
  const handleSwitch = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ml: 2,
        mr: 2,
      }}
    >
      <Typography variant="body2">{leftValue}</Typography>
      <StyledSwitch
        checked={isChecked ? isChecked : false}
        onClick={handleSwitch}
      />
      <Typography variant="body2">{rightValue}</Typography>
    </Box>
  );
};

export default YSSwitchPlain;
