import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import EditToolbar from "./EditToolbar";
import {
  DataGridPremium,
  useGridApiRef,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid-premium";
import { useSnackbar } from "notistack";
import ConfirmRowEditDialog from "./ConfirmRowEditDialog";

export default function DataGridEditable({
  gridRef,
  rows,
  columns,
  setRowModesModel,
  setRows,
  rowModesModel,
  processRowUpdate,
  handleRowModeModelChange,
  onRowClick,
  showAddRowButton = true, 
}) {
  const defaultRef = useGridApiRef();
  const apiRef = gridRef || defaultRef;
  const { enqueueSnackbar } = useSnackbar();
  const noButtonRef = React.useRef(null);
  const [promiseArguments, setPromiseArguments] = React.useState(null);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleProcessRowUpdateError = () => {
    enqueueSnackbar("An error occured in handling the rowUpdate", {
      variant: "warning",
    });
  };

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    if (oldRow?.isNew)
      setRows((oldRows) => oldRows.filter((row) => row.id !== oldRow.id));
    else {
      resolve(oldRow);
    }
    setPromiseArguments(null);
  };

  const handleRowDoubbleClick = (params, event) => {
    console.log("handleRowDoubbleClick", params, event.target);
    var targetElement = event.target;
    if (!targetElement.getAttribute("data-colindex")) {
      targetElement = targetElement.parentNode;
    }
    var colIndex = targetElement.getAttribute("data-colindex");
    const selectedRow = params.row;

    if (selectedRow) {
      const focusField =
        apiRef.current.getVisibleColumns()[colIndex ?? 0]?.field;
      apiRef.current.setCellFocus(selectedRow.id, focusField);
      setRowModesModel((oldRowModesModel) => ({
        ...oldRowModesModel,
        [selectedRow.id]: { mode: GridRowModes.Edit, cellToFocus: focusField },
      }));
    }
  };

  const handleYes = async () => {
    const { oldRow, newRow, resolve } = promiseArguments;
    try {
      const updateSuccess = await processRowUpdate(oldRow, newRow);

      if (updateSuccess) {
        enqueueSnackbar(
          newRow.isNew
            ? "Row created successfully"
            : "Row updated successfully",
          {
            variant: "success",
          }
        );
        newRow.isNew = false;
        resolve(newRow);
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      resolve(oldRow);
      enqueueSnackbar("Failed to update the row", {
        variant: "error",
      });
    } finally {
      setPromiseArguments(null);
    }
  };

  const handleProcessRowUpdate = async (newRow, oldRow) => {
    return await new Promise((resolve) => {
      setPromiseArguments({ resolve, oldRow, newRow });
    });
  };

  return (
    <Fragment>
      {promiseArguments && (
        <ConfirmRowEditDialog
          apiRef={apiRef}
          noButtonRef={noButtonRef}
          handleNo={handleNo}
          handleYes={handleYes}
          promiseArguments={promiseArguments}
        />
      )}

      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DataGridPremium
            apiRef={apiRef}
            rows={rows ?? []}
            columns={columns ?? []}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            onRowDoubleClick={handleRowDoubbleClick}
            onRowModesModelChange={handleRowModeModelChange}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            onRowClick={onRowClick}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: {
                setRowModesModel,
                setRows,
                rowModesModel,
                showAddRowButton, 
              },
            }}
          />
        </div>
      </Box>
    </Fragment>
  );
}

DataGridEditable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  rowModesModel: PropTypes.object.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
  processRowUpdate: PropTypes.func.isRequired,
  handleRowModeModelChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  showAddRowButton: PropTypes.bool, 
};