import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
export function ConfirmActionDialog({
  open,
  onClose,
  onAccept,
  onDecline,
  text,
  title,
}) {
  const { t } = useTranslation();
  return (
    <Dialog title={title} open={open} onClose={onClose}>
      <DialogContent>{text ?? t("general.cancel")}</DialogContent>
      <DialogActions>
        <Button onClick={onDecline} variant="contained" color="error">
          {t("general.no")}
        </Button>
        <Button onClick={onAccept} variant="contained" color="primary">
          {t("general.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmActionDialog;
