import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import ParticlesComp from "components/ParticlesComp";
import { Outlet } from "react-router";
import { Copyright } from "components/CopyRight";
import { useGetBrandingQuery } from "api/apiSlice";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline"; // For consistent styling

const LoginLayout = () => {
  const { data: branding } = useGetBrandingQuery();
  const { t } = useTranslation();

  // State to store branding details from localStorage or API
  const [logoUrl, setLogoUrl] = useState(null);
  const [clientName, setClientName] = useState("Yalmar Solutions");
  const [backgroundImage, setBackgroundImage] = useState(null);

  // Effect to handle caching logic
  useEffect(() => {
    const cachedLogo = localStorage.getItem('logoUrl');
    const cachedClientName = localStorage.getItem('clientName');
    const cachedBackgroundImage = localStorage.getItem('backgroundImage');

    // If cached data is found, use it
    if (cachedLogo) setLogoUrl(cachedLogo);
    if (cachedClientName) setClientName(cachedClientName);
    if (cachedBackgroundImage) setBackgroundImage(cachedBackgroundImage);

    // If branding is fetched from API and not already in localStorage, store it
    if (branding) {
      if (branding.default_logo_img) {
        const logo = branding.default_logo_img;
        setLogoUrl(logo);
        localStorage.setItem('logoUrl', logo); // Store logo in localStorage
      }
      if (branding.group_name) {
        const name = branding.group_name;
        setClientName(name);
        localStorage.setItem('clientName', name); // Store client name in localStorage
      }
      if (branding.background_img) {
        const bgImage = branding.background_img;
        setBackgroundImage(bgImage);
        localStorage.setItem('backgroundImage', bgImage); // Store background image in localStorage
      }
    }
  }, [branding]);

  // Create theme from branding color scheme
  const themePalette = branding?.template_setting?.color_scheme?.palette;
  const brandingTheme = createTheme({
    palette: {
      primary: {
        main: themePalette?.primary || "#1976d2", // Default fallback if no branding colors
      },
      secondary: {
        main: themePalette?.secondary || "#dc004e",
      },
      background: {
        default: themePalette?.background || "#f5f5f5",
      },
    },
  });

  return (
    <ThemeProvider theme={brandingTheme}>
      <CssBaseline />
      <Box
        sx={{
          background: backgroundImage
            ? `url(${backgroundImage}) center/cover no-repeat`
            : (theme) => `
              repeating-linear-gradient(
                to right, 
                ${theme.palette.primary.main} 0%,       
                ${theme.palette.primary.dark} 1%,       
                ${theme.palette.primary.main} 2%,      
                ${theme.palette.primary.light} 3%      
              ),
              radial-gradient(circle, 
                rgba(255, 255, 255, 0.2) 0%,            
                rgba(0, 0, 0, 0.1) 100%                 
              )
            `,
          height: "100vh",
          display: "grid",
          gridTemplateColumns: { xs: "1fr" },
          alignItems: "center",
          justifyItems: "center",
          padding: 0,
          margin: 0,
        }}
      >
        {/* Render Particles */}
        <Box sx={{ height: "100%", width: "100%" }}>
          <ParticlesComp />
        </Box>

        {/* Render Client Name only if no logo is available */}
        {!logoUrl && clientName && (
          <Typography
            variant="h6"
            color="inherit"
            sx={{ 
              padding: 0, 
              marginTop: -20,
              lineHeight: 1,  
              fontSize: '3rem',  
              fontWeight: 'bold',  
              fontFamily: 'Calibri',
              zIndex: 4000,
            }}
          >
            {clientName}
          </Typography>
        )}

        {/* Render Logo if available */}
        {logoUrl && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: -20,
              zIndex: 4000, 
            }}
          >
            <img
              src={logoUrl}
              alt="Company Logo"
              style={{
                maxWidth: "250px", 
                height: "auto",    
                marginBottom: 0, 
                padding: 0, 
              }}
            />
          </Box>
        )}

        {/* Render login form */}
        <Paper
          sx={{
            width: { xs: "80%", md: "600px", lg: "600px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: -10,
            padding: 2,
            boxShadow: "0px 0px 15px 2px rgba(0,0,0,0.1)",
            borderRadius: 2,
            backgroundColor: "rgba(255, 255, 255, 0.9)", 
            position: "relative",
            zIndex: 4000,  
          }}
        >
          <Outlet />
        </Paper>

        {/* Render Copyright */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            zIndex: 4000,
          }}
        >
          <Copyright loginPage={true} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LoginLayout;
