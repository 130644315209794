//REACT IMPORTS
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { PlotlyChart } from "components/PlotlyChart";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useGetCurrencyExposureChartQuery } from "api/apiSlice";

export const CurrencyExposureChart = ({ date }) => {
  const currencyExposureChartQuery = useGetCurrencyExposureChartQuery(
    { endDate: date },
    { skip: !date }
  );
  return (
    <RTKQueryWrapper query={currencyExposureChartQuery}>
      {(data) => {
        return (
          <Card
            sx={{
              marginTop: "80px",
              marginLeft: "40px",
              backgroundColor: "primary.dark",
              color: "primary.contrastText",
              overflow: "visible",
            }}
            elevated="true"
          >
            <CardContent>
              <Paper
                sx={{
                  textAlign: "center",
                  backgroundColor: "info.main",
                  color: "info.contrastText",
                  margin: "-80px 0px 0px -40px",
                  borderRadius: "100px 0px 100px 0px",
                }}
              >
                <PlotlyChart {...data}></PlotlyChart>
              </Paper>
            </CardContent>
          </Card>
        );
      }}
    </RTKQueryWrapper>
  );
};
export default CurrencyExposureChart;
