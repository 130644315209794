import React, { useState } from "react";

import { calcTotalTradeAmount } from "./utils";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { Divider, Typography, IconButton } from "@mui/material";
import { ICONS } from "../../../assets/Icons";
import CreateTransactionForm from "./CreateTransactionForm";

export const EditableTransactionCard = ({
  data,
  formSupplementData,
  isOpen,
  onDelete,
  onSave,
  isEditing,
  apiRef,
}) => {
  const [state, setState] = useState({
    open: isOpen || false,
    isEditing: isEditing || false,
  });
  const handleClose = () => {
    setState((oldState) => {
      return { ...oldState, isEditing: false };
    });
  };
  return (
    <Box
      key={data.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: data?.processed ? "secondary.light" : "secondary.main",
        color: data?.processed
          ? (theme) =>
              theme.palette.getContrastText(theme.palette.secondary.light)
          : "secondary.contrastText",
        p: 1,
        m: 0.5,
        borderRadius: 1,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection="row"
      >
        <Stack
          direction={"row"}
          spacing={1}
          width={"90%"}
          divider={<Divider orientation="vertical" flexItem></Divider>}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Portfolio"}</Typography>
            {data?.transactionInfo?.portfolio}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Instrument type"}</Typography>
            {data?.transactionInfo?.instrumentType}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Total trade amount"}</Typography>
            {calcTotalTradeAmount(
              data?.transactionInfo?.cost,
              data?.transactionInfo?.price,
              data?.transactionInfo?.amount,
              data?.transactionInfo?.currency,
              data?.transactionInfo?.transactionType
            )}
          </Box>
        </Stack>
        <IconButton
          sx={{
            color: data.processed
              ? (theme) =>
                  theme.palette.getContrastText(theme.palette.secondary.light)
              : "secondary.contrastText",
          }}
          onClick={() =>
            setState((oldState) => {
              return { ...oldState, open: !oldState.open };
            })
          }
        >
          {state.open ? ICONS.arrowUp() : ICONS.arrowDown()}
        </IconButton>
        {!!onSave && (
          <IconButton
            sx={{
              color: data.processed
                ? (theme) =>
                    theme.palette.getContrastText(theme.palette.secondary.light)
                : "secondary.contrastText",
            }}
            onClick={() => {
              setState((oldState) => {
                return { ...oldState, isEditing: true, open: true };
              });
            }}
          >
            {ICONS.edit()}
          </IconButton>
        )}
        {!!onDelete && (
          <IconButton
            sx={{
              color: data.processed
                ? (theme) =>
                    theme.palette.getContrastText(theme.palette.secondary.light)
                : "secondary.contrastText",
            }}
            onClick={() => onDelete(data)}
          >
            {ICONS.delete()}
          </IconButton>
        )}
      </Box>

      {state.open && (
        <Stack direction={"column"} divider={<Divider></Divider>}>
          {state.isEditing ? (
            <CreateTransactionForm
              formSupplementData={formSupplementData}
              defaultValues={data?.transactionInfo}
              id={data?.uuid}
              onSave={onSave}
              onClose={handleClose}
              apiRef={apiRef}
            ></CreateTransactionForm>
          ) : data ? (
            Object.entries(data?.transactionInfo ?? {}).map(([key, value]) => {
              if (Array.isArray(value)) {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">
                      {` ${value?.length} `}{" "}
                    </Typography>
                  </Box>
                );
              } else if (typeof value === "object") {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">{value?.label}</Typography>
                  </Box>
                );
              } else if (typeof value === "boolean") {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">
                      {value &&
                        ICONS.checked({ sx: { color: "success.main" } })}
                    </Typography>
                  </Box>
                );
              }
              return (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography> {key}</Typography>
                  <Typography variant="body2">{value}</Typography>
                </Box>
              );
            })
          ) : (
            <Typography> {"No Data"}</Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};
export default EditableTransactionCard;