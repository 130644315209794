import { createSlice } from "@reduxjs/toolkit";
import { api } from "api/apiSlice";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isLoggedInn: false,
    error: null,
    loginLoading: false,
  },
  reducers: {
    authLogout: (state) => {
      state.token = null;
      state.isLoggedInn = false;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
      state.token = action.payload.key;
      state.isLoggedInn = true;
    });
  },
});

export const { authLogout, clearError } = authSlice.actions;
export default authSlice.reducer;
