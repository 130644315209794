import React, { Children } from "react";
import { Box } from "@mui/material";

import PropTypes from "prop-types";

export const CenterContentWrapper = ({ children }) => {
  return Children.only(
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-100px",
        width: "100%",
        minHeight: "100px",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
};

CenterContentWrapper.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};
CenterContentWrapper.displayName = "CenterContentWrapper";
export default CenterContentWrapper;
