import { KEEP_UNUSED_DATA as keepUnusedDataFor } from "constants";

export const managementEndpoints = (builder) => {
  return {
    /**
     * UserManagement endpoints
     **/
    getClientUserList: builder.query({
      query: () => {
        return {
          url: `usermanagement/client_user_list/`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CLIENT_USERS" }],
    }),
    getClientUserDetails: builder.query({
      query: ({ id }) => {
        return {
          url: `usermanagement/client_user_detail/${id}/`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CLIENT_USER_DETAIL" }],
    }),
    updateClientUser: builder.mutation({
      query: ({ id, ...patch }) => {
        return {
          url: `usermanagement/client_user_detail/${id}/`,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: patch,
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_USER_DETAIL" }],
    }),
    deleteClientUser: builder.mutation({
      query: ({ id }) => {
        return {
          url: `usermanagement/client_user_detail/${id}/`,
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_USER_DETAIL" }],
    }),
    createClientUser: builder.mutation({
      query: (newUser) => {
        return {
          url: `usermanagement/client_user_create/`,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: newUser,
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_USER_DETAIL" }],
    }),    
    getCreateUserOptions: builder.query({
      query: () => {
        return {
          url: `usermanagement/create_user_options/`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "USER_OPTIONS" }],
    }),
    /**
     * ClientManagement endpoints
     **/
    getClientDetails: builder.query({
      query: (id) => {
        return {
          url: `usermanagement/client_detail/${id}/`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }],
    }),
    createClient: builder.mutation({
      query: (newcilent) => {
        return {
          url: `usermanagement/create_client/`,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: newcilent,
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }],
    }), 
    updateClient: builder.mutation({
      query: ({ id, ...patch }) => {
        return {
          url: `usermanagement/client_detail/${id}/`,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: patch,
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }],
    }),
    deleteClient: builder.mutation({
      query: ({ id }) => {
        return {
          url: `usermanagement/client_detail/${id}/`,
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      invalidatesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }],
    }),
    getClientList: builder.query({
      query: () => {
        return {
          url: `usermanagement/client_list/`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CLIENT_LIST" }],
    }),   
        /**
     * Group endpoints
     **/
        getClientCutoffDate: builder.query({
          query: () => {
            return {
              url: `usermanagement/cut_off_date/`,
              method: "GET",
              headers: { "Content-Type": "application/json" },
            };
          },
          keepUnusedDataFor,
          providesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }, { type: 'CutoffDate', id: 'LIST' }],
        }),
        updateClientCutoffDate: builder.mutation({
          query: (patch) => ({
            url: `usermanagement/cut_off_date/`,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: patch,
          }),
          keepUnusedDataFor,
          invalidatesTags: [{ type: "FAData", id: "CLIENT_DETAIL" }, { type: 'CutoffDate', id: 'LIST' }],
        }),
    /**
     * END
     **/
  };
};