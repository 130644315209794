import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const SnackbarEffect = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (snackbar.message) {
      enqueueSnackbar(snackbar.message, { variant: snackbar.type });
    }
  }, [snackbar, enqueueSnackbar]);

  return null;
};
