import React from "react";
import LoginRoutes from "./LoginRoutes";
import ApplicationRoutes from "./ApplicationRoutes";
import { useSelector } from "react-redux";

const MainRoutes = () => {
  const user = useSelector((state) => state.user?.user);
  const isLoggedIn = !!user;
  if (!isLoggedIn) {
    return <LoginRoutes />;
  }

  return <ApplicationRoutes />;
};

export default MainRoutes;
