import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { YS_COLORS } from "themes/YsTheme";
const useYsTheme = () => {
  const user = useSelector((state) => state.user);
  const [theme, setTheme] = useState(YS_COLORS);

  useEffect(() => {
    // Retrieve the user's preferred theme from local storage
    const savedTheme = JSON.parse(localStorage.getItem("theme"));
    // If a theme is saved in local storage, use it
    if (user?.settings?.theme) {
      setTheme(user?.settings?.theme);
    } else if (user?.chosen_client?.settings?.color_scheme?.palette) {
      setTheme(user?.chosen_client?.settings?.color_scheme?.palette);
    } else if (
      user?.chosen_client?.settings?.template_settings?.color_scheme?.palette
    ) {
      setTheme(
        user?.chosen_client?.settings?.template_settings?.color_scheme?.palette
      );
    } else if (savedTheme) {
      setTheme(savedTheme);
    } else {
      // Otherwise, use the default theme
      setTheme(YS_COLORS);
    }
  }, [
    user?.settings?.theme,
    user?.chosen_client?.settings?.color_scheme?.palette,
    user?.chosen_client?.settings?.template_settings?.color_scheme?.palette,
  ]);

  return theme;
};

export default useYsTheme;
