import { createSlice } from "@reduxjs/toolkit";
import { api } from "api/apiSlice";
import { authLogout } from "./authSlice";
import { format, startOfYear, startOfYesterday } from "date-fns";
import LogRocket from "logrocket";
import sha256 from "crypto-js/sha256";
import { changeLanguage } from "i18next";
const DATE_PRESETS = {
  inception: "inception",
  ytd: "ytd",
};
const initialState = {
  client: null,
  data: null,
  startDate: DATE_PRESETS.inception,
  customStartDate: format(startOfYear(new Date()), "yyyy-MM-dd"),
  customEndDate: format(startOfYesterday(new Date()), "yyyy-MM-dd"),
  presetStartDate: DATE_PRESETS.inception, // ytd || inception,
  presetEndDate: format(startOfYesterday(new Date()), "yyyy-MM-dd"),
  endDate: format(startOfYesterday(new Date()), "yyyy-MM-dd"),
  minDate: null,
  maxDate: null,
  settings: null,
  user: null,
  chosen_client: null,
  client_list: null,
  error: null,
  loading: false,
  userSubscription: null,
};
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    clearData: (state) => {
      state.data = null;
    },
    setClient: (state, action) => {
      state.chosen_client = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setPresetStartDate: (state, action) => {
      state.presetStartDate = action.payload;
    },
    setPresetEndDate: (state, action) => {
      state.presetEndDate = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setPresetDates: (state, action) => {
      state.presetStartDate = action.payload.startDate;
      state.presetEndDate = action.payload.endDate;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setCustomStartDate: (state, action) => {
      state.customStartDate = action.payload;
    },
    setCustomEndDate: (state, action) => {
      state.customEndDate = action.payload;
    },
    setMinDate: (state, action) => {
      state.minDate = action.payload;
    },
    setMaxDate: (state, action) => {
      state.maxDate = action.payload;
    },
    setUserSubscription: (state, action) => {
      state.userSubscription = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogout, (state) => {
        Object.keys(initialState).forEach((key) => {
          state[key] = initialState[key];
        });
      })
      .addMatcher(
        api.endpoints.getUserProfile.matchFulfilled,
        (state, action) => {
          Object.entries(action.payload).forEach(([key, value]) => {
            state[key] = value;
          });
          if (action.payload?.chosen_client?.settings?.languageCode) {
            changeLanguage(
              action.payload?.chosen_client?.settings?.languageCode
            );
          }
          const clientSettings = action.payload?.chosen_client?.settings;
          const userSettings = action.payload?.settings;
          if (clientSettings?.min_date) {
            // Apply default settings first
            state.minDate = clientSettings.min_date;
            if (clientSettings.template_settings?.min_date_override) {
              const overrideStartDate =
                clientSettings.template_settings.min_date_override;
              state.minDate = overrideStartDate;
            }
          }
          if (userSettings?.max_date) {
            state.maxDate = userSettings.max_date;
            state.endDate = userSettings.max_date;
            state.customEndDate = userSettings.max_date;
          } else if (clientSettings?.max_date) {
            // Apply default settings first
            state.endDate = clientSettings.max_date;
            state.customEndDate = clientSettings.max_date;
            state.maxDate = clientSettings.max_date;

            // Override with template_settings if they exist
            if (clientSettings.template_settings?.max_date_override) {
              const overrideDate =
                clientSettings.template_settings.max_date_override;
              state.maxDate = overrideDate;
              state.endDate = overrideDate;
              state.customEndDate = overrideDate;
            }
          }

          if (import.meta.env.MODE !== "production") {
            const userID = sha256(
              `${action.payload.username} ${action.payload.id}`
            ).toString();
            LogRocket.identify(userID, {
              name: action.payload.username,
              email: action.payload.username,
            });
          }
          if (clientSettings?.template_settings?.color_scheme?.palette) {
            localStorage.setItem(
              "theme",
              JSON.stringify(
                clientSettings.template_settings?.color_scheme?.palette
              )
            );
          } else if (clientSettings?.color_scheme?.palette) {
            localStorage.setItem(
              "theme",
              JSON.stringify(clientSettings?.color_scheme?.palette)
            );
          } else if (userSettings?.theme) {
            localStorage.setItem("theme", JSON.stringify(userSettings.theme));
          }
        }
      )
      .addMatcher(
        api.endpoints.changeClient.matchFulfilled,
        (state, action) => {
          state.chosen_client = action.payload.chosen_client;
          state.loading = false;
          const clientSettings = action.payload?.chosen_client?.settings;
          const userSettings = action.payload?.settings;
          if (clientSettings?.min_date) {
            // Apply default settings first
            state.minDate = clientSettings.min_date;
            if (clientSettings.template_settings?.min_date_override) {
              const overrideStartDate =
                clientSettings.template_settings.min_date_override;
              state.minDate = overrideStartDate;
            }
          }
          if (userSettings?.max_date) {
            state.maxDate = userSettings.max_date;
            state.endDate = userSettings.max_date;
            state.customEndDate = userSettings.max_date;
          } else if (clientSettings?.max_date) {
            // Apply default settings first
            state.endDate = clientSettings.max_date;
            state.customEndDate = clientSettings.max_date;
            state.maxDate = clientSettings.max_date;

            // Override with template_settings if they exist
            if (clientSettings.template_settings?.max_date_override) {
              const overrideDate =
                clientSettings.template_settings.max_date_override;
              state.maxDate = overrideDate;
              state.endDate = overrideDate;
              state.customEndDate = overrideDate;
            }
          }
          if (clientSettings?.languageCode) {
            changeLanguage(clientSettings?.languageCode);
          }
          if (clientSettings?.template_settings?.color_scheme?.palette) {
            localStorage.setItem(
              "theme",
              JSON.stringify(
                clientSettings?.template_settings?.color_scheme?.palette
              )
            );
          } else if (clientSettings?.color_scheme?.palette) {
            localStorage.setItem(
              "theme",
              JSON.stringify(clientSettings?.color_scheme?.palette)
            );
          } else if (userSettings?.theme) {
            localStorage.setItem("theme", JSON.stringify(userSettings.theme));
          }
        }
      )
      .addMatcher(api.endpoints.changeClient.matchPending, (state) => {
        state.loading = true;
      })

      .addMatcher(api.endpoints.changeClient.matchRejected, (state) => {
        state.loading = false;
      })
      .addMatcher(
        api.endpoints.updateClientSettings.matchFulfilled,
        (state, action) => {
          if (state.chosen_client) {
            state.chosen_client.settings = action.payload;
          }
        }
      )
      .addMatcher(
        api.endpoints.updateUserProfile.matchFulfilled,
        (state, action) => {
          state.data = action.payload;
          state.settings = action.payload.settings;
        }
      )
      .addMatcher(
        api.endpoints.updateUserSettings.matchFulfilled,
        (state, action) => {
          state.settings = action.payload;
          if (action.payload?.theme) {
            localStorage.setItem(
              "theme",
              JSON.stringify(action.payload?.theme)
            );
          }
        }
      );
  },
});

export const {
  clearData,
  clearError,
  setClient,
  setStartDate,
  setEndDate,
  setMinDate,
  setMaxDate,
  setCustomEndDate,
  setCustomStartDate,
  setPresetEndDate,
  setPresetStartDate,
  setSettings,
  setPresetDates,
} = userSlice.actions;
export default userSlice.reducer;
