import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { getDate } from "../../utils/functions";
import { startOfYear } from "date-fns";
const initialState = {
  animation: "overlay",
  hide: "250",
  show: "1000",
  visible: false,
  TabsKey: null,
  startDate: getDate(startOfYear(new Date())),
  endDate: null,
  maxDate: null,
  minDate: null,
  presetDate: "inception",
  refetch: 0,
  themeColors: JSON.parse(localStorage.getItem("userTheme")),
};

const setVisibility = (state, action) => {
  return updateObject(state, {
    visible: action.payload,
  });
};
const setChartData = (state, action) => {
  return updateObject(state, {
    [action.key]: action.value,
  });
};
const setTabsValue = (state, action) => {
  return updateObject(state, {
    [`${action.id}TabsKey`]: action.value,
  });
};

const setStartDate = (state, action) => {
  return updateObject(state, {
    [`${action.id}startDate`]: action.value,
  });
};
const setEndDate = (state, action) => {
  return updateObject(state, {
    [`${action.id}endDate`]: action.value,
  });
};
const setMaxDate = (state, action) => {
  return updateObject(state, {
    [`${action.id}maxDate`]: action.value,
  });
};
const setMinDate = (state, action) => {
  return updateObject(state, {
    [`${action.id}minDate`]: action.value,
  });
};
const setPresetDate = (state, action) => {
  return updateObject(state, {
    [`${action.id}presetDate`]: action.value,
  });
};
const setDateError = (state, action) => {
  return updateObject(state, {
    [`${action.id}DateError`]: action.value,
  });
};
const refetch = (state, action) => {
  return updateObject(state, {
    refetch: state.refetch + 1,
  });
};

const setGridState = (state, action) => {
  return updateObject(state, {
    [`${action.id}GridState`]: action.value,
  });
};
const setTransactionsModalState = (state, action) => {
  return updateObject(state, {
    transactionsModalState: action.value,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VISIBILITY_SIDEBAR:
      return setVisibility(state, action);
    case actionTypes.SET_CHART_DATA:
      return setChartData(state, action);
    case actionTypes.SET_TABS_VALUE:
      return setTabsValue(state, action);
    case actionTypes.SET_START_DATE:
      return setStartDate(state, action);
    case actionTypes.SET_END_DATE:
      return setEndDate(state, action);
    case actionTypes.SET_MAX_DATE:
      return setMaxDate(state, action);
    case actionTypes.SET_MIN_DATE:
      return setMinDate(state, action);
    case actionTypes.SET_PRESET_DATE:
      return setPresetDate(state, action);
    case actionTypes.SET_DATE_ERROR:
      return setDateError(state, action);
    case actionTypes.SET_GRID_STATE:
      return setGridState(state, action);
    case actionTypes.SET_TRANSACTIONS_MODAL_STATE:
      return setTransactionsModalState(state, action);
    case actionTypes.REFETCH:
      return refetch(state, action);
    default:
      return state;
  }
};

export default reducer;
