// RefreshButton.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from '@mui/icons-material/Refresh'; 
import { useTranslation } from "react-i18next";
import { api } from "../../api/apiSlice";
import { FA_DATA_TAGS } from "api/tags";
import { triggerLazyQuery, resetTrigger } from "store/slices/queryTriggerSlice";
import { useLocation } from "react-router-dom";

const RefreshButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // Get user data from state
  const is_superuser = useSelector((state) => state.user.user.is_superuser);
  const is_client_admin = useSelector((state) => state.user.user.is_client_admin);
  
  // Create a variable to check if refresh is available
  const refreshAvailable = is_superuser || is_client_admin;

  const dataLoading = useSelector((state) => {
    return Object.values(state.api.queries).some((query) => query.pending);
  });

  const { pathname } = useLocation();

  const handleRefresh = () => {
    const key = pathname.split("/").pop() || pathname;

    // Dispatch triggerLazyQuery with key and extraOptions
    dispatch(
      triggerLazyQuery(key, { refresh: true })
    );
  
    // Invalidate tags to refresh the data
    dispatch(api.util.invalidateTags(FA_DATA_TAGS));
  
    // Reset the triggers to avoid affecting the next call
    dispatch(resetTrigger()); // This will clear the state
  };

  // Only show the refresh button if refreshAvailable is true
  if (!refreshAvailable) {
    return null;
  }

  return (
    <Tooltip title={t("general.refresh")} placement="bottom">
      <IconButton
        edge="start"
        sx={{ color: "primary.contrastText" }}
        aria-label="refresh"
        variant="contained"
        size="large"
        onClick={handleRefresh}
      >
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RefreshButton;
