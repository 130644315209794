import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

export const FormInputRadio = ({
  options,
  name,
  control,
  label,
  size,
  isRequired,
  type,
  disabled,
  maxLength,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: isRequired, message: label + " is required" },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <Fragment>
            <FormLabel id="form-controlled-radio-buttons-group">
              {label}
            </FormLabel>
            <RadioGroup
              aria-labelledby="form-controlled-radio-buttons-group"
              {...field}
            >
              {options.map((item, index) => (
                <FormControlLabel
                  key={item.key}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Fragment>
        );
      }}
    />
  );
};

FormInputRadio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};
export default FormInputRadio;
