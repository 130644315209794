import React from "react";
import { useLocation } from "react-router-dom";
import { Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PathDisplay = ({ hideHeader }) => {
  const location = useLocation();
  const pages = useSelector((state) => state.user.settings.pages);
  const { t } = useTranslation();
  // Extract the last part of the path
  const currentPath = location.pathname.slice(5).split("/");
  // Find the page config that matches the current path
  const currentPageConfig = pages.find((page) => page.path === currentPath[0]);
  // Use the display_name from the config, or default to showing the path if not found
  const headerTitle = t(
    `navBar.pageTitles.${currentPageConfig?.key.toLowerCase()}`,
    {
      defaultValue:
        currentPageConfig?.display_name ||
        t(`navBar.pageTitles.${currentPath[0]}`),
    }
  );

  return (
    <Toolbar sx={{ position: "relative" }}>
      {!hideHeader && (
        <Typography
          variant="h5"
          color={"primary.contrastText"}
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {headerTitle}
        </Typography>
      )}
    </Toolbar>
  );
};

export default PathDisplay;
