import React, { Fragment } from "react";
import { useGetCurrenciesExposureDetailQuery } from "api/apiSlice";
import { useSelector } from "react-redux";
import { RTKQueryWrapper } from "components/DataFetchWrapper/RTKQueryWrapper";
import { DataGridBasic } from "components/Tables/DataGridTable";
import { Box, Toolbar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
export const FXContracts = () => {
  const date = useSelector((state) => state.user.endDate);
  const [view, setView] = React.useState({
    value: "fxContractsContractView",
    label: "Contract",
  });
  const currencyExposureDetailsQuery = useGetCurrenciesExposureDetailQuery(
    { endDate: date },
    {
      skip: !date,
    }
  );
  const handleViewChange = (event, value) => {
    setView(value);
  };
  const viewByOptions = [
    { value: "fxContractsContractView", label: "Contract" },
    { value: "fxContractsLegView", label: "Leg" },
  ];
  return (
    <Fragment>
      <Toolbar sx={{ bgcolor: "info.main" }}>
        <Autocomplete
          sx={{
            width: 300,
          }}
          disableClearable
          value={view}
          options={viewByOptions}
          isOptionEqualToValue={(option, value) => {
            return option?.value === value?.value;
          }}
          renderOption={(props, option) => (
            <MenuItem {...props} value={option}>
              {option.label}
            </MenuItem>
          )}
          variant="outlined"
          label="View by"
          size="small"
          onChange={handleViewChange}
          renderInput={(params) => <TextField label={"View by"} {...params} />}
        />
      </Toolbar>
      <Box sx={{ p: 2 }}>
        <RTKQueryWrapper query={currencyExposureDetailsQuery}>
          {(data) => (
            <DataGridBasic data={data[view?.value]} autoHeight={true} />
          )}
        </RTKQueryWrapper>
      </Box>
    </Fragment>
  );
};

export default FXContracts;
