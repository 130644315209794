import React, { useState } from "react";

import Plot from "react-plotly.js";
import { retrieveData } from "./utils";
import { useTheme } from "@mui/material/styles";

const AttributionPlots = ({ data, light }) => {
  const theme = useTheme();

  const [selected, setSelected] = useState("");
  const handleClick = (e) => {
    if (e.points && selected === "") {
      setSelected(e.points[0].x);
    } else {
      setSelected("");
    }
  };
  const handleReset = (e) => {
    setSelected("");
  };
  if (!data) return null;
  const { chart_data, chart_config } = retrieveData(data, selected);

  const layout = {
    title: {
      text: chart_config.title,
      font: {
        color: light ? "white" : theme.palette.primary.dark,
      },
    },
    autoSize: true,
    legend: {
      font: {
        family: "sans-serif",
        size: 12,
        color: light ? "#ffffff" : theme.palette.text.secondary,
      },
    },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    xaxis: {
      automargin: true,
      tickfont: {
        family: "sans-serif",
        size: 9,
        color: light ? "#ffffff" : theme.palette.text.secondary,
      },
      color: light ? "#ffffff" : theme.palette.text.secondary,
      gridcolor: light ? "666666" : theme.palette.secondary.dark,
      zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
      linecolor: "#f3f3f3",
    },
    yaxis: {
      automargin: true,
      color: light ? "#ffffff" : theme.palette.text.secondary,
      gridcolor: light ? "666666" : theme.palette.secondary.dark,
      zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
      linecolor: "#f3f3f3",
    },
    modebar: {
      activecolor: theme.palette.secondary.light,
      color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
  };
  const config = {
    displaylogo: false,
    responsive: true,
    modeBarButtonsToAdd: [
      {
        name: "Reset",
        icon: {
          height: "22",
          width: "22",
          path: "M12,5V1L7,6l5,5V7c3.31,0,6,2.69,6,6s-2.69,6-6,6s-6-2.69-6-6H4c0,4.42,3.58,8,8,8s8-3.58,8-8S16.42,5,12,5z",
        },
        click: handleReset,
      },
    ],
    modeBarButtonsToRemove: [
      "pan2d",
      "select2d",
      "lasso2d",
      "resetScale2d",
      "zoomOut2d",
    ],
  };

  const plotlyStyles = {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    minHeight: "500px",
  };

  return (
    <Plot
      data={chart_data}
      layout={layout}
      style={plotlyStyles}
      config={config}
      onClick={handleClick}
    ></Plot>
  );
};

AttributionPlots.propTypes = {};

export default AttributionPlots;
