//AUTHENTICATION
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

//PASSWORD MANAGEMENT
export const PASS_CHANGE_START = "PASS_CHANGE_START";
export const PASS_CHANGE_SUCCESS = "PASS_CHANGE_SUCCESS";
export const PASS_CHANGE_FAIL = "PASS_CHANGE_FAIL";
export const PASS_RESET_START = "PASS_RESET_START";
export const PASS_RESET_SUCCESS = "PASS_RESET_SUCCESS";
export const PASS_RESET_FAIL = "PASS_RESET_FAIL";
export const PASS_RESET_CONFIRM_START = "PASS_RESET_CONFIRM_START";
export const PASS_RESET_CONFIRM_SUCCESS = "PASS_RESET_CONFIRM_SUCCESS";
export const PASS_RESET_CONFIRM_FAIL = "PASS_RESET_CONFIRM_FAIL";

//UTILITIES
export const DATA_FLUSH = "DATA_FLUSH";
export const REFETCH = "REFETCH";
//PE
export const DATA_FETCH_START_PE = "DATA_FETCH_START_PE";
export const DATA_FETCH_SUCCESS_PE = "DATA_FETCH_SUCCESS_PE";
export const DATA_FETCH_FAIL_PE = "DATA_FETCH_FAIL_PE";
export const DATA_FETCH_START_PE_CHART = "DATA_FETCH_START_PE_CHART";
export const DATA_FETCH_SUCCESS_PE_CHART = "DATA_FETCH_SUCCESS_PE_CHART";
export const DATA_FETCH_FAIL_PE_CHART = "DATA_FETCH_FAIL_PE_CHART";
export const DATA_F_FAIL = "DATA_F_FAIL";
export const DATA_F_START = "DATA_F_START";
export const DATA_F_SUCCESS = "DATA_F_SUCCESS";

export const MULTI_FETCH_DATA_START = "MULTI_FETCH_DATA";
export const MULTI_FETCH_DATA_SUCCESS = "MULTI_FETCH_DATA_SUCCESS";
export const MULTI_FETCH_DATA_FAIL = "MULTI_FETCH_DATA_FAIL";

//GENERAL
export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_VISIBILITY_SIDEBAR = "SET_VISIBILITY_SIDEBAR";
export const SET_TABS_VALUE = "SET_TABS_VALUE";
export const SET_THEME = "SET_THEME";
export const SET_NUMBER_FORMATS = "SET_NUMBER_FORMATS";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_DATE = "SET_DATE";
export const SET_MAX_DATE = "SET_MAX_DATE";
export const SET_MIN_DATE = "SET_MIN_DATE";
export const SET_PRESET_DATE = "SET_PRESET_DATE";
export const SET_DATE_ERROR = "SET_DATE_ERROR";
export const SET_GRID_STATE = "SET_GRID_STATE";

// USER
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SETTINGS_FAIL = "SETTINGS_FAIL";
export const SETTINGS_START = "SETTINGS_START";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";

// DirectInvestments

export const SET_TRANSACTIONS_MODAL_STATE = "SET_TRANSACTIONS_MODAL_STATE";
