import React, { forwardRef } from "react";
import { Tooltip } from "@mui/material";
import LoadingButton from "../Buttons/LoadingButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import useFileDownloader, {
  useFileDownload,
} from "../../hooks/useFileDownloader";
const FileDownloaderIcon = forwardRef(
  ({ document, idx, tooltipText, documentName }, ref) => {
    const [downloadFile, downloaderComponentUI] = useFileDownloader();
    const download = (file) => downloadFile(file);
    if (document) {
      return downloaderComponentUI ? (
        downloaderComponentUI
      ) : (
        <Tooltip title={tooltipText} enterDelay={100}>
          <LoadingButton
            ref={ref}
            key={idx}
            sx={{ width: "100%" }}
            size={"small"}
            endIcon={<GetAppIcon />}
            onClick={() => download(document)}
          >
            {documentName}
          </LoadingButton>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
);
export const FileDownloaderIconURL = ({
  url,
  idx,
  tooltipText,
  documentName,
}) => {
  const { onDownload, DownloadStatus } = useFileDownload();
  if (url) {
    return DownloadStatus ? (
      DownloadStatus
    ) : (
      <LoadingButton
        tooltipText={tooltipText}
        key={idx}
        size={"small"}
        endIcon={<GetAppIcon />}
        onClick={() => onDownload("GET", url, null, tooltipText)}
      >
        {documentName}
      </LoadingButton>
    );
  } else {
    return null;
  }
};

export default FileDownloaderIcon;
