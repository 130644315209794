import React from "react";

import { useSelector } from "react-redux";

//Mui
import { AppBar as MUIAppBar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DatePickerContainer from "./DatePickerContainer";
import { UserInfo } from "./UserInfo";
import { ICONS } from "assets/Icons";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import PathDisplay from "components/AppBar/PathDisplay";
import RefreshButton from "./RefreshButton";

export default function AppBar({
  onNavBarToggle,
  isPermanentNavBar,
  hideHeader,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <MUIAppBar position="relative">
      <Toolbar
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "primary.dark" : "primary.main",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {!isPermanentNavBar && (
            <Tooltip title={t("general.sideMenu")} placement="bottom">
              <IconButton
                edge="start"
                sx={{ color: "primary.contrastText" }}
                aria-label="open drawer"
                onClick={onNavBarToggle}
                variant="contained"
                size="large"
              >
                {ICONS.menu()}
              </IconButton>
            </Tooltip>
          )}
          {!hideHeader && !isMobile && <PathDisplay />}
        </Box>

        {!isMobile && <ActiveClientLogo />}
        <DatePickerContainer />
        <RefreshButton />
        <UserInfo></UserInfo>
      </Toolbar>
    </MUIAppBar>
  );
}

const ActiveClientLogo = () => {
  const client = useSelector((state) => state.user.chosen_client);
  return (
    <Box
      sx={{
        flexGrow: 0.8,
        color: "primary.contrastText",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        ml: 1,
        height: (theme) => theme.mixins.toolbar.minHeight,
      }}
    >
      {client?.logo_img && (
        <img src={`${client.logo_img}`} alt={""} height={"80%"} />
      )}
    </Box>
  );
};
