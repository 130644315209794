import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
// YS ACTIONS
import { formatValues } from "../../../utils/functions";
import { useTranslation } from "react-i18next";

export default function CustomCard({ data }) {
  const { t } = useTranslation();
  console.log(data);
  return (
    <Box style={{ marginTop: "50px" }}>
      {Object.entries(data)?.map(([key, item], index) => {
        if (item.key === "title") {
          return (
            <Typography
              key={item.key}
              sx={{
                textAlign: "center",
                fontSize: 24,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.dark,
              }}
            >
              {t("dashboardPage.mvBoxes.title", {
                defaultValue: item?.label,
                ...item,
              })}
            </Typography>
          );
        }
        return (
          <Card
            key={index}
            sx={{
              marginTop: "20px",
              backgroundColor: (theme) => theme.palette.primary.main,
              overflow: "visible",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) =>
                    theme.palette.getContrastText(theme.palette.primary.main),
                  textAlign: "center",
                }}
              >
                {t(`dashboardPage.mvBoxes.${item.key}`, {
                  defaultValue: item?.label,
                  ...item,
                })}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: (theme) =>
                    theme.palette.getContrastText(theme.palette.primary.main),
                  textAlign: "center",
                }}
              >
                {item?.value !== 0
                  ? formatValues(
                      item?.value,
                      -1 < item?.value
                        ? item?.value > 1
                          ? "millions"
                          : "percent"
                        : "millions"
                    )
                  : item?.value}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
}
