import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { unregister } from "./registerServiceWorker";
import { LicenseInfo } from "@mui/x-license-pro";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./main.css";
import { store } from "./redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import App from "App";

LicenseInfo.setLicenseKey(
  "26c301eb9abb773d001d33a5db8cc552Tz05NTk3NixFPTE3NTUwODU4MTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);
if (import.meta.env.MODE === "production") {
  LogRocket.init("hbd9yg/ys-react-reporting-app", {
    network: {
      requestSanitizer: (request) => {
        request.headers["Authorization"] = undefined;
        request.body = undefined;
        return request;
      },
      responseSanitizer: (response) => {
        response.body = undefined;
        return response;
      },
    },
  });
  setupLogRocketReact(LogRocket);
}
createRoot(document.getElementById("root") as HTMLDivElement).render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </I18nextProvider>
  </StrictMode>
);

unregister();
