import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import { styled, lighten, darken } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export const FormInputMulti = ({
  options = [],
  callBack,
  name,
  control,
  label,
  size,
  disabled,
  isRequired,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: { value: isRequired, message: label + " is required" },
      }}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => {
        const handleChange = (e, data, reason) => {
          if (callBack) {
            callBack(e, data, reason);
          }
          if (reason === "clear") {
            return onChange(undefined);
          }
          onChange(data);
        };
        return (
          <Autocomplete
            multiple={true}
            groupBy={(option) => option.groupBy}
            renderGroup={(params) => (
              <li>
                <GroupHeader>{params.group.toUpperCase()}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            id={name}
            size={size || "medium"}
            options={
              options.sort((a, b) =>
                a.groupBy === b.groupBy
                  ? a.label?.localeCompare(b.label)
                  : -a.groupBy?.localeCompare(b.groupBy)
              ) || []
            }
            getOptionLabel={(option) => option.label}
            openOnFocus
            disabled={disabled}
            {...field}
            onChange={handleChange}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={ref}
                  error={Boolean(error)}
                  helperText={error?.message}
                  required={Boolean(isRequired)}
                  fullWidth
                  variant="outlined"
                  label={label}
                ></TextField>
              );
            }}
          ></Autocomplete>
        );
      }}
    />
  );
};
FormInputMulti.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInputMulti;
