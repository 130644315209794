import React from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useFileDownloader } from "hooks/useFileDownloader";

export const FileDownloaderCell = ({ document, idx }) => {
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  return (
    <MenuItem
      key={idx}
      onClick={() =>
        downloaderComponentUI
          ? console.log("downloading")
          : downloadFile(document)
      }
    >
      {downloaderComponentUI ? (
        downloaderComponentUI
      ) : (
        <ListItemIcon>
          <GetAppIcon></GetAppIcon>
        </ListItemIcon>
      )}

      <ListItemText primary={document.fileName} />
    </MenuItem>
  );
};
