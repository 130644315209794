import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Fade, Grid, CardActions } from "@mui/material";
import YSSwitchPlain from "components/YSSwitchPlain";
import CustomCard from "./CustomCard";
import CustomChart from "./CustomChart";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { PlotlyChartDrilldown } from "components/PlotlyChart";
import {
  useGetAssetAllocationQuery,
  useGetTotalMarketValueAndReturnCustomQuery,
  useGetWaterfallQuery,
} from "api/apiSlice";

function CPageDashboard() {
  const startDate = useSelector((state) => state.user.presetStartDate);
  const endDate = useSelector((state) => state.user.endDate);
  const [waterDisplay, setWaterDisplay] = React.useState("Cash");
  const handleWaterDisplay = () => {
    setWaterDisplay((prev) => (prev === "Cash" ? "TWR" : "Cash"));
  };
  
  
  const totalMarketValueAndReturnCustomQuery =
    useGetTotalMarketValueAndReturnCustomQuery({
      startDate: startDate,
      endDate: endDate,
    });

  const waterfallQuery = useGetWaterfallQuery({
    startDate: startDate,
    endDate: endDate,
  });
  const assetAllocationQuery = useGetAssetAllocationQuery({
    startDate: startDate,
    endDate: endDate,
  });

  return (
    <Grid container spacing={2}>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={5} xl={5}>
          <RTKQueryWrapper
            query={assetAllocationQuery}
            LoadingComponent={CircularProgress}
          >
            {(data) => (
              <PlotlyChartDrilldown
                orientation={"left"}
                data={data}
              ></PlotlyChartDrilldown>
            )}
          </RTKQueryWrapper>
        </Grid>
      </Fade>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={2} xl={2}>
          <RTKQueryWrapper
            query={totalMarketValueAndReturnCustomQuery}
            LoadingComponent={CircularProgress}
          >
            {(data) => <CustomCard data={data} />}
          </RTKQueryWrapper>
        </Grid>
      </Fade>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={5} xl={5}>
          <RTKQueryWrapper
            query={waterfallQuery}
            LoadingComponent={CircularProgress}
          >
            {(data) => (
              <CustomChart
                data={waterDisplay === "Cash" ? data.cash : data.twr}
                orientation={"right"}
                //switchChecked={waterDisplay === "TWR"}
                //handleSwitch={handleWaterDisplay}
                //Actions={" "}
              />
            )}
          </RTKQueryWrapper>
        </Grid>
      </Fade>
    </Grid>
  );
}

export default CPageDashboard;
