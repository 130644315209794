import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Fade, Grid, Card, CardContent } from "@mui/material";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useGetTopBottomContributorsQuery } from "api/apiSlice";
import ChartOne from "../cPages002/ChartOne";

function CPageSecurityPerformance() {
  const startDate = useSelector((state) => state.user.presetStartDate);
  const endDate = useSelector((state) => state.user.endDate);

  const topBottomContributorsQuery = useGetTopBottomContributorsQuery({
    startDate: startDate,
    endDate: endDate,
  });

  return (
    <Grid container spacing={2}>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={12} xl={12}>
          <Card sx={{ m: 2, minHeight: "100%" }}>
            <CardContent sx={{ p: 0 }}>
              <RTKQueryWrapper query={topBottomContributorsQuery}>
                {(data) => <ChartOne data={data} />}
              </RTKQueryWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
    </Grid>
  );
}

export default CPageSecurityPerformance;
