import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

export function Copyright({ loginPage = false}) {
  const theme = useTheme();
  
  // Get appropriate text color based on the background color
  const backgroundColor = loginPage ? theme.palette.primary.main : theme.palette.background.default;

  console.log("backgroundColor", backgroundColor)

  
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Typography variant="body2" align="center" sx={{ color: textColor }}>
      {"Copyright © "}
      <Link color="inherit" href="https://yalmar.com/">
        Yalmar Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
