export const pageSorter = (a, b) => {
  const orderA = a.order;
  const orderB = b.order;

  // Check if both orders exist
  if (orderA && orderB) {
    return orderA - orderB;
  }

  // If orderA is not a number, but orderB is, orderA should come after orderB
  if (!orderA && orderB) {
    return 1; // Move a to the end
  }

  // If orderB is not a number, but orderA is, orderB should come after orderA
  if (orderA && !orderB) {
    return -1; // Keep a before b
  }

  // If neither orderA nor orderB is a number, keep their original order
  return 0;
};
