import { useTranslation as useOriginalTranslation } from "react-i18next";

/**
 * Custom hook to extend the functionality of the `t` function.
 */
export const useExtendedTranslation = () => {
  const { t: originalT, i18n } = useOriginalTranslation();

  /**
   * Extended translation function.
   *
   * @param {string|object} keyOrObject - Translation key or object with translation details.
   * @param {object} [options] - Translation options if the first argument is a string.
   * @param {boolean} [isChartTitle] - Flag to indicate if the key is a chart title.
   * @returns {string} - Translated string.
   */
  const t = (keyOrObject, options, isChartTitle = false) => {
    let translationKey = keyOrObject;

    if (typeof keyOrObject === "string") {
      if (isChartTitle && !keyOrObject.startsWith("plotly.charts.titles.")) {
        // If it's a chart title and doesn't have the prefix, add it
        translationKey = `plotly.charts.titles.${keyOrObject}`;
      }
    } else if (typeof keyOrObject === "object") {
      const { key, value, translateValue } = keyOrObject;

      let translatedValue = value;
      if (translateValue) {
        translatedValue = originalT(value);
      }

      return originalT(key, { ...options, value: translatedValue });
    }

    return originalT(translationKey, options);
  };

  return { t, i18n };
};

export default useExtendedTranslation;
