import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  triggers: {}, // Object to hold trigger states
};

const queryTriggersSlice = createSlice({
  name: "queryTriggers",
  initialState,
  reducers: {
    triggerLazyQuery: {
      reducer: (state, action) => {
        const { key, value, extraOptions } = action.payload;
        state.triggers[key] = { value, extraOptions };
      },
      prepare: (key, extraOptions = {}, value = nanoid()) => {
        return { payload: { key, value, extraOptions } };
      },
    },
    // New resetTrigger action to invalidate state after use
    resetTrigger: (state) => {
      state.triggers = {}; // Reset the triggers to the initial state
    },
  },
});

export const { triggerLazyQuery, resetTrigger } = queryTriggersSlice.actions;

export default queryTriggersSlice.reducer;
