import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Plot from "react-plotly.js";
import _ from "lodash";

import { DEFAULT_SELECTOR_OPTIONS } from "./constants";
import { useExtendedTranslation } from "hooks/useExtendedTranslation";
import { PLOTLY_ICONS } from "assets/Icons";
import * as norwegian from "plotly.js/lib/locales/no";
import * as Plotly from "plotly.js/dist/plotly";
import { setBackground } from "./utils";
/**
 * @description This is generic ReactPlotly chart with connected styles from material design
 * @param {data, dataError, dataLoading, light, onClick,  isRangeSlider, isCumulative} = props;
 * @returns React.JSXElement
 */

export const ControlledPlotlyChart = ({
  data,
  light = false,
  onClick,
  minHeightChart,
  customSelectorOptions,
  isRangeSlider,
}) => {
  const { t, i18n } = useExtendedTranslation();
  const theme = useTheme();
  const [chartData, setChartData] = useState(data?.chart?.chart_data);
  const selectorOptions = customSelectorOptions || DEFAULT_SELECTOR_OPTIONS;

  const layout = _.defaultsDeep(
    {
      title: {
        text: t(data?.chart?.chart_config?.title, undefined, true),
        font: {
          family: "Rubik, Calibri, sans-serif",
          size: 24,
          color: light ? "white" : theme.palette.text.primary,
        },
      },
      font: {
        family: "Rubik, Calibri, sans-serif",
      },
      legend: {
        font: {
          family: "Calibri, sans-serif",
          size: 16,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },
      autosize: true,
      dragmode: false,

      showAxisDragHandles: false,
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      xaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        rangeselector: isRangeSlider ? selectorOptions : undefined,
        rangeslider: { visible: isRangeSlider || false },
        automargin: true,
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },
      yaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
      },
      xaxis2: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },
      yaxis2: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
      },

      modebar: {
        activecolor: theme.palette.primary.light,
        bgcolor: "transparent",
        color:
          theme.palette.mode === "dark"
            ? "#ffffff"
            : theme.palette.primary.main,
      },
    },
    data?.chart?.chart_config?.layout
  );
  const config = {
    displaylogo: false,
    locale: i18n.language,
    locales: { no: norwegian },
    responsive: true,
    displayModeBar: true,
    toImageButtonOptions: {
      format: "jpeg",
      filename:
        chartData?.chart_config?.title || data?.chart?.chart_config?.title,
      setBackground: setBackground,
    },

    modeBarButtonsToRemove: [
      "pan2d",
      "select2d",
      "lasso2d",
      "resetScale2d",
      "zoomOut2d",
      "zoomIn2d",
      "zoom2d",
      "autoScale2d",
      "toImage",
    ],
    modeBarButtonsToAdd: [
      {
        name: "downloadImageAsJpeg",
        title: "Download as JPEG",
        icon: PLOTLY_ICONS.downloadAsJPEG(1000, 1000),
        click: function (gd) {
          Plotly?.downloadImage(gd, {
            setBackground: setBackground,
            format: "jpeg",
            width: 1080,
            height: 720,
            filename: t(chartData?.title),
          });
        },
      },
    ],
  };

  const plotlyStyles = {
    width: "100%",
    height: "100%",
    minHeight: minHeightChart || "500px",
  };
  const handleOnClick = (e) => {
    if (onClick) {
      onClick(e, chartData, setChartData);
    }
  };

  return (
    <Plot
      // onRelayout={handleRelayout}
      // onInitialized={() => setFigure(data.chart.chart_data)}

      // onClick={handleOnClick}
      data={chartData}
      layout={layout}
      style={plotlyStyles}
      config={config}
      useResizeHandler={true}
    ></Plot>
  );
};
export default ControlledPlotlyChart;
