import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GetAppIcon from "@mui/icons-material/GetApp";
import PieChartIcon from "@mui/icons-material/PieChart";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import SaveRounded from "@mui/icons-material/SaveRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import SegmentRoundedIcon from "@mui/icons-material/SegmentRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import EditOffRoundedIcon from "@mui/icons-material/EditOffRounded";
import AddchartRoundedIcon from "@mui/icons-material/AddchartRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import NotInterestedRoundedIcon from "@mui/icons-material/NotInterestedRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MoneyOffCsredRoundedIcon from "@mui/icons-material/MoneyOffCsredRounded";
export const ICONS = {
  account: (props) => <AccountCircleIcon {...props} />,
  add: (props) => <AddBoxRoundedIcon {...props} />,
  addChart: (props) => <AddchartRoundedIcon {...props} />,
  addNote: (props) => <NoteAddRoundedIcon {...props} />,
  addTable: (props) => <PostAddRoundedIcon {...props} />,
  arrowDown: (props) => <KeyboardArrowDownIcon {...props} />,
  arrowRight: (props) => <KeyboardArrowRightIcon {...props} />,
  arrowUp: (props) => <KeyboardArrowUpIcon {...props} />,
  chartPie: (props) => <PieChartIcon {...props} />,
  checked: (props) => <CheckRoundedIcon {...props} />,
  color: (props) => <ColorLensRoundedIcon {...props} />,
  contact: (props) => <ContactSupportIcon {...props} />,
  dateRange: (props) => <DateRangeRoundedIcon {...props} />,
  delete: (props) => <DeleteIcon {...props} />,
  deny: (props) => <NotInterestedRoundedIcon {...props} />,
  download: (props) => <GetAppIcon {...props} />,
  dropDown: (props) => <ArrowDropDownIcon {...props} />,
  dropUp: (props) => <ArrowDropUpIcon {...props} />,
  edit: (props) => <EditRoundedIcon {...props} />,
  editOff: (props) => <EditOffRoundedIcon {...props} />,
  exit: (props) => <ExitToAppIcon {...props} />,
  expandDown: (props) => <ArrowCircleDownRoundedIcon {...props} />,
  expandUp: (props) => <ArrowCircleUpRoundedIcon {...props} />,
  feedback: (props) => <FeedbackIcon {...props} />,
  filterList: (props) => <FilterListIcon {...props} />,
  groupBy: (props) => <SegmentRoundedIcon {...props} />,
  info: (props) => <InfoIcon {...props} />,
  list: (props) => <FormatListBulletedRoundedIcon {...props} />,
  menu: (props) => <MenuRoundedIcon {...props} />,
  openModal: (props) => <MenuOpenRoundedIcon {...props} />,
  pdf: (props) => <PictureAsPdfRoundedIcon {...props} />,
  reset: (props) => <RotateLeftRoundedIcon {...props} />,
  saveRounded: (props) => <SaveRounded {...props} />,
  setttings: (props) => <SettingsIcon {...props} />,
  visibilityOff: (props) => <VisibilityOffRoundedIcon {...props} />,
  visibilityOn: (props) => <VisibilityRoundedIcon {...props} />,
  noTransaction: (props) => <MoneyOffCsredRoundedIcon {...props} />,
};

export const PLOTLY_ICONS = {
  reset: (width = "1em", height = "1em") => ({
    path: "M 6.56 7.98 C 6.1 7.52 5.31 7.6 5 8.17 c -0.28 0.51 -0.5 1.03 -0.67 1.58 c -0.19 0.63 0.31 1.25 0.96 1.25 h 0.01 c 0.43 0 0.82 -0.28 0.94 -0.7 c 0.12 -0.4 0.28 -0.79 0.48 -1.17 c 0.22 -0.37 0.15 -0.84 -0.16 -1.15 Z M 5.31 13 h -0.02 c -0.65 0 -1.15 0.62 -0.96 1.25 c 0.16 0.54 0.38 1.07 0.66 1.58 c 0.31 0.57 1.11 0.66 1.57 0.2 c 0.3 -0.31 0.38 -0.77 0.17 -1.15 c -0.2 -0.37 -0.36 -0.76 -0.48 -1.16 c -0.12 -0.44 -0.51 -0.72 -0.94 -0.72 Z m 2.85 6.02 c 0.51 0.28 1.04 0.5 1.59 0.66 c 0.62 0.18 1.24 -0.32 1.24 -0.96 v -0.03 c 0 -0.43 -0.28 -0.82 -0.7 -0.94 c -0.4 -0.12 -0.78 -0.28 -1.15 -0.48 c -0.38 -0.21 -0.86 -0.14 -1.16 0.17 l -0.03 0.03 c -0.45 0.45 -0.36 1.24 0.21 1.55 Z M 13 4.07 v -0.66 c 0 -0.89 -1.08 -1.34 -1.71 -0.71 L 9.17 4.83 c -0.4 0.4 -0.4 1.04 0 1.43 l 2.13 2.08 c 0.63 0.62 1.7 0.17 1.7 -0.72 V 6.09 c 2.84 0.48 5 2.94 5 5.91 c 0 2.73 -1.82 5.02 -4.32 5.75 c -0.41 0.12 -0.68 0.51 -0.68 0.94 v 0.02 c 0 0.65 0.61 1.14 1.23 0.96 C 17.57 18.71 20 15.64 20 12 c 0 -4.08 -3.05 -7.44 -7 -7.93 Z",
    width,
    height,
  }),
  downloadAsJPEG: (width = "1em", height = "1em") => ({
    path: "m500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z",
    transform: "matrix(1 0 0 -1 0 850)",
    width,
    height,
  }),
};
