import React from "react";
import Grid from "@mui/material/Grid";
import { PlotlyChartDrilldown } from "components/PlotlyChart/PlotlyChartDrilldown";
import { RTKLazyQueryWrapper } from "components/DataFetchWrapper";
import { useLazyGetAllocationsDynamicPieChartsQuery } from "api/apiSlice";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, FormControlLabel, Checkbox, Box } from "@mui/material";
import LoadingButton from "components/Buttons/LoadingButton";
import { triggerLazyQuery } from "store/slices/queryTriggerSlice";
import { useExtendedTranslation } from "hooks/useExtendedTranslation";

const Allocations = () => {
  const { t, i18n } = useExtendedTranslation();
  const { startDate, endDate } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const initialSetupDone = React.useRef(false);
  const [filters, setFilters] = React.useState([]);
  const allocationChartQuery = useLazyGetAllocationsDynamicPieChartsQuery();

  const handleFilterChange = (event) => {
    const code = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked && !filters.includes(code)) {
      setFilters([...filters, code]);
    } else if (!isChecked && filters.includes(code)) {
      setFilters(filters.filter((filter) => filter !== code));
    }
  };

  const handleChartClick = (id, chartData) => {
    console.log(id, chartData);
  };

  const handleRefetch = () => {
    dispatch(triggerLazyQuery("allocationsDynamicPieCharts", Date.now()));
  };

  React.useEffect(() => {
    if (allocationChartQuery.data && !initialSetupDone.current) {
      // Automatically check all checkboxes from the start
      setFilters(
        allocationChartQuery.data.choices.map((choice) => choice.code)
      );
      initialSetupDone.current = true;
    }
  }, [allocationChartQuery.data]);

  return (
    <RTKLazyQueryWrapper
      query={allocationChartQuery}
      triggerKey={"allocationsDynamicPieCharts"}
      queryParams={{ startDate: startDate, endDate: endDate, filters: filters }}
      fetchOnMount={true}
    >
      {(data) => (
        <Grid
          container
          minHeight={"100%"}
          spacing={2}
          justifyContent={"center"}
        >
          <Grid item xs={12} justifyContent={"center"}>
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <FormGroup row>
                {data?.choices?.map((choice) => {
                  return (
                    <FormControlLabel
                      key={choice.code}
                      control={
                        <Checkbox
                          checked={filters.includes(choice.code)}
                          onChange={handleFilterChange}
                          value={choice.code}
                        />
                      }
                      label={choice.name}
                    />
                  );
                })}
              </FormGroup>
              <LoadingButton
                variant={"contained"}
                color={"primary"}
                onClick={handleRefetch}
                loading={allocationChartQuery[1].isFetching}
                disabled={allocationChartQuery[1].isFetching}
              >
                {t("cpage.boxes.applyFilters")}
              </LoadingButton>
            </Box>
          </Grid>
          {data?.charts?.map((chart, idx) => {
            return (
              <Grid item xs={12} lg={6} xl={4} key={idx}>
                <Box minHeight={"80vh"}>

                  <PlotlyChartDrilldown
                    data={chart}
                    onClick={handleChartClick}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </RTKLazyQueryWrapper>
  );
};

export default Allocations;
