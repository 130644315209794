import React, { Fragment, useState, useEffect } from "react";

import { useDispatch } from "react-redux";

//REACT ROUTER IMPORTS
import { Link as RouterLink, useNavigate } from "react-router-dom";

//MATERIAL UI IMPORTS
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useLoginMutation, useLazyGetUserProfileQuery } from "api/apiSlice";
import { authLogout } from "store/slices/authSlice";
import { useSnackbar } from "notistack";
import { log } from "logrocket";
import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { pageSorter } from "utils/pageSorter";
const initialState = {
  username: "",
  password: "",
};

const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: initialState });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [login, authState] = useLoginMutation();
  const [userTrigger] = useLazyGetUserProfileQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const findFirstPage = (pages) => {
    if (pages?.length > 0) {
      const sortedPages = [...pages].sort(pageSorter);
      return sortedPages[0].path;
    }

    return "dashboard";
  };

  const onSubmit = async (e) => {
    if (e.username && e.password) {
      setIsLoading(true);
      try {
        await login(e).unwrap();
        const userData = await userTrigger();
        if (userData.isSuccess) {
          if (!userData?.data?.settings?.pages?.length > 0) {
            enqueueSnackbar(
              t(`notisstack.auth.missingPageSubscriptionsError`),
              {
                variant: "error",
              }
            );
            dispatch(authLogout());
            return;
          }
          navigate(`/app/${findFirstPage(userData?.data?.settings?.pages)}`);
        } else {
          enqueueSnackbar(t(`notisstack.auth.userFetchingError`), {
            variant: "error",
          });
          dispatch(authLogout());
        }
      } catch (e) {
        log(e);
        if (e.status === "FETCH_ERROR") {
          enqueueSnackbar(e?.message ?? t(`notisstack.auth.generalError`), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(e?.data?.message ?? t(`notisstack.auth.loginError`), {
            variant: "error",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    reset();
  }, [reset, authState.isSuccess]);

  return (
    <Fragment>
      <Avatar
        sx={{
          m: 1,
          backgroundColor: "palette.secondary",
        }}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" color="inherit">
        {t(`loginPage.title`)}
      </Typography>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component={"form"}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TextField
          label={t(`loginPage.username`)}
          required
          fullWidth
          autoComplete="username"
          margin="normal"
          variant="outlined"
          {...register("username", {
            required: t(`loginPage.usernameRequired`),
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            //   message: 'Invalid email address',
            // },
          })}
          error={!!errors.username}
          helperText={errors.username ? errors.username.message : ""}
        />

        <TextField
          label={t(`loginPage.password`)}
          fullWidth
          autoComplete="current-password"
          margin="normal"
          required
          variant="outlined"
          type="password"
          {...register("password", {
            required: t(`loginPage.passwordRequired`),
          })}
          error={!!errors.password}
          helperText={errors.password ? errors.password.message : ""}
        />

        <Button
          endIcon={isLoading && <CircularProgress size={20} />}
          disabled={isLoading}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          {t(`loginPage.login`)}
        </Button>
      </Box>
      {authState.isError && (
        <Typography color={"error"}>{authState?.error?.message}</Typography>
      )}
      <RouterLink
        sx={{
          mt: 3,
          p: 0.5,
          backgroundColor: "palette.info.main",
          borderRadius: 1,
        }}
        to={`/login/reset`}
      >
        {t(`loginPage.forgotPassword`)}
      </RouterLink>
    </Fragment>
  );
};
export default Login;
