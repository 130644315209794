import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CenterContentWrapper from "containers/CenterContentWrapper/CenterContentWrapper";
import { CircularProgress } from "@mui/material";
export const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  let token = useSelector((store) => store.auth.token);
  let loading = useSelector((store) => store.auth.loading);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);
  if (loading) {
    return (
      <CenterContentWrapper>
        <CircularProgress></CircularProgress>
      </CenterContentWrapper>
    );
  }
  if (!token) return null;
  return children;
};

export default PrivateRoute;
