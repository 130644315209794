import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import AppBar from "components/AppBar";
import { NavBar } from "components/NavBar/NavBar";
import Box from "@mui/material/Box";
import { Copyright } from "components/CopyRight";
import { useOpenCloseState } from "hooks/useOpenCloseState";
import CenterContentWrapper from "containers/CenterContentWrapper/CenterContentWrapper";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

export default function MainLayout() {
  const { close, toggle, isOpen } = useOpenCloseState();
  const isPermanent = useSelector(
    (state) => state.user?.chosen_client?.settings?.permanent_sidebar
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showNavbar = isPermanent && !isMobile;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: `${showNavbar ? "240px" : "auto"} 1fr`,
        },
        gridTemplateRows: { xs: "64px 1fr 1.4rem" },
        gridTemplateAreas: {
          xs: `"header header"
               "navbar main"
               "footer footer"`,
        },
        height: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{ gridArea: "header", display: "flex", flexDirection: "column" }}
      >
        <AppBar onNavBarToggle={toggle} isPermanentNavBar={showNavbar} />
      </Box>
      <Box sx={{ gridArea: "navbar", width: { sx: "60px" } }}>
        <NavBar
          anchor={"left"}
          onClose={close}
          open={isOpen}
          isPermanent={showNavbar}
        />
      </Box>
      <Box
        sx={{
          gridArea: "main",
          overflow: "overlay",
          height: "100%",
          // Additional styling can go here
        }}
      >
        <Suspense
          fallback={
            <CenterContentWrapper>
              <CircularProgress />
            </CenterContentWrapper>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
      <Box className="footer" sx={{ gridArea: "footer" }}>
        <Copyright />
      </Box>
    </Box>
  );
}
