import React, { Suspense } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { SnackbarEffect } from "components/SnackBars/SnackbarEffect";
import { CustomSnackbar } from "components/SnackBars";
import { YS_DEFAULT_THEME, DEFAULTCOLORS } from "./themes/YsTheme";

import CenterContentWrapper from "./containers/CenterContentWrapper";
import { CircularProgress } from "@mui/material";
import MainRoutes from "routes/MainRoutes";
import useYsTheme from "hooks/useYsTheme";
import { useGetBrandingQuery } from "api/apiSlice";

function App() {

  // const { data: branding, error: brandingError, isLoading: brandingLoading } = useGetBrandingQuery();

  // if (brandingLoading) {
  //   return (
  //     <CenterContentWrapper>
  //       <CircularProgress />
  //     </CenterContentWrapper>
  //   );
  // }

  // const themePalette = branding?.template_setting?.color_scheme?.palette
  // const theme = createTheme(YS_DEFAULT_THEME({ ...DEFAULTCOLORS, ...themePalette || {} }));

  // old way of creating theme:
  const ysTheme = useYsTheme();
  const theme = createTheme(YS_DEFAULT_THEME(ysTheme));
  console.log("theme:", theme);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        Components={{
          default: CustomSnackbar,
        }}
      >
        <SnackbarEffect />
        <CssBaseline />
        <Suspense
          fallback={
            <CenterContentWrapper>
              <CircularProgress></CircularProgress>
            </CenterContentWrapper>
          }
        >
          <MainRoutes />
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
App.displayName = "App";

export default App;
