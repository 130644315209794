import React, { Fragment } from "react";

import { useForm } from "react-hook-form";

import {
  FormInputDate,
  FormInputText,
  FormInputNumber,
} from "components/FormComponents";
import { calcTotalTradeAmount } from "./utils";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormInputCheckbox from "components/FormComponents/FormInputCheckBox";
import FormInputAutoComplete from "components/FormComponents/FormInputAutoComplete";
import FormInputMulti from "components/FormComponents/FormInputMulti";
import { Divider, Typography } from "@mui/material";
import { TRANSACTION_FORM_DEFAULT_VALUES } from "./utils";
import { useTranslation } from "react-i18next";
const CreateTransactionForm = ({
  defaultValues,
  formId,
  formSupplementData,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    resetField,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : TRANSACTION_FORM_DEFAULT_VALUES,
    mode: "onChange",
  });
  const { t } = useTranslation();
  const onPortfolioChange = () => {
    resetField("bank_account");
  };
  const onInstrumentTypeChange = () => {
    resetField("security_name");
    resetField("transaction_type");
    resetField("cost");
    resetField("price");
    resetField("amount");
    resetField("total_quantity");
  };

  const onSecurityNameChange = (e, data) => {
    if (data) {
      setValue("currency", data.currency, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: false,
      });
    }
  };

  const onTransactionDateAccept = (date) => {
    setValue("settlement_date", date, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: false,
    });
  };

  const [cost, price, amount, currency, transactionType] = watch([
    "cost",
    "price",
    "amount",
    "currency",
    "transaction_type",
  ]);
  const calcMarketValue = (price, amount, currency) => {
    let mv = "";
    const tmp1 = Number(amount) * Number(price);
    try {
      mv = Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(tmp1);
    } catch (error) {
      mv = Intl.NumberFormat(undefined, {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(tmp1);
    }
    return mv;
  };
  return (
    <Paper
      elevation={2}
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "light" ? "info.light" : "info.dark",
        color: "info.contrastText",
        flex: 1,
        p: 1,
      }}
    >
      <Stack
        component="form"
        justifyContent="space-between"
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        spacing={1}
      >
        <Box sx={{ width: "100%" }}>
          <FormInputAutoComplete
            options={formSupplementData?.instrumentType?.valueOptions}
            control={control}
            disabled={formSupplementData?.instrumentType?.disabled}
            callback={onInstrumentTypeChange}
            name={"instrument_type"}
            size="small"
            label={t("inputs.labels.instrumentType")}
          ></FormInputAutoComplete>
        </Box>

        <Box sx={{ width: "100%", pl: 4 }}>
          <FormInputAutoComplete
            options={formSupplementData?.transactionType?.valueOptions}
            dependencies={["instrument_type"]}
            disabled={formSupplementData?.transactionType?.disabled}
            watch={watch}
            control={control}
            name={"transaction_type"}
            size="small"
            label={t("inputs.labels.transactionType")}
          ></FormInputAutoComplete>
        </Box>
        <Box sx={{ width: "100%", pl: 4 }}>
          <FormInputAutoComplete
            options={formSupplementData?.securityName?.valueOptions}
            dependencies={["instrument_type"]}
            disabled={formSupplementData?.securityName?.disabled}
            callback={onSecurityNameChange}
            watch={watch}
            control={control}
            name={"security_name"}
            size="small"
            label={t("inputs.labels.securityName")}
          ></FormInputAutoComplete>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputAutoComplete
            options={formSupplementData?.portfolio?.valueOptions}
            control={control}
            name={"portfolio"}
            size="small"
            label={t("inputs.labels.portfolio")}
            callback={onPortfolioChange}
          ></FormInputAutoComplete>
        </Box>
        <Box sx={{ width: "100%", pl: 4 }}>
          <FormInputAutoComplete
            options={formSupplementData?.bank_account?.valueOptions}
            dependencies={["portfolio"]}
            disabled={formSupplementData?.bank_account?.disabled}
            watch={watch}
            control={control}
            name={"bank_account"}
            size="small"
            label={t("inputs.labels.bankAccount")}
          ></FormInputAutoComplete>
        </Box>
        <Box sx={{ width: "100%", pl: 8 }}>
          <FormInputText
            disabled={formSupplementData?.currency?.disabled}
            dependencies={["security_name"]}
            control={control}
            name={"currency"}
            size="small"
            label={t("inputs.labels.currency")}
          ></FormInputText>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputDate
            options={formSupplementData?.transactionDate?.valueOptions}
            control={control}
            disabled={formSupplementData?.transactionDate?.disabled}
            variant="outlined"
            callback={onTransactionDateAccept}
            name={"transaction_date"}
            size="small"
            label={t("inputs.labels.transactionDate")}
          ></FormInputDate>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputDate
            options={formSupplementData?.settlementDate?.valueOptions}
            control={control}
            disabled={formSupplementData?.settlementDate?.disabled}
            variant="outlined"
            name={"settlement_date"}
            size="small"
            label={t("inputs.labels.settlementDate")}
          ></FormInputDate>
        </Box>
        {["PEFUND", "PE"].includes(getValues("instrument_type")) ? (
          <Fragment>
            <Box sx={{ width: "100%" }}>
              <FormInputNumber
                control={control}
                disabled={formSupplementData?.amount?.disabled}
                name={"amount"}
                label={t("inputs.labels.quantity")}
              ></FormInputNumber>
            </Box>
            <Stack
              component={"div"}
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                mb: 1,
                opacity: 0.6,
                pl: 4,
              }}
            >
              <Typography variant="body1">{"Total trade amount:"}</Typography>
              <Typography>
                {calcTotalTradeAmount(0, 1, amount, currency, transactionType)}{" "}
              </Typography>
            </Stack>
          </Fragment>
        ) : (
          <Fragment>
            <Box sx={{ width: "100%" }}>
              <FormInputNumber
                control={control}
                disabled={formSupplementData?.amount?.disabled}
                name={"amount"}
                label={t("inputs.labels.amount")}
              ></FormInputNumber>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormInputNumber
                control={control}
                disabled={formSupplementData?.price?.disabled}
                name={"price"}
                label={t("inputs.labels.price")}
              ></FormInputNumber>
            </Box>
            <Stack
              component={"div"}
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                mb: 1,
                opacity: 0.6,
                pl: 4,
              }}
            >
              <Typography variant="body1">{"Market value:"}</Typography>
              <Typography>
                {" "}
                {calcMarketValue(price, amount, currency)}{" "}
              </Typography>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <FormInputNumber
                control={control}
                isRequired={false}
                disabled={formSupplementData?.cost?.disabled}
                name={"cost"}
                minValue={0}
                label={t("inputs.labels.cost")}
              ></FormInputNumber>
            </Box>
            <Stack
              component={"div"}
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                mb: 1,
                opacity: 0.6,
                pl: 4,
              }}
            >
              <Typography variant="body1">{"Total trade amount:"}</Typography>
              <Typography>
                {calcTotalTradeAmount(
                  cost,
                  price,
                  amount,
                  currency,
                  transactionType
                )}{" "}
              </Typography>
            </Stack>
          </Fragment>
        )}

        <Box sx={{ width: "100%" }}>
          <FormInputText
            control={control}
            multiline={true}
            isRequired={false}
            disabled={formSupplementData?.description?.disabled}
            name={"description"}
            label={t("inputs.labels.description")}
          ></FormInputText>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputNumber
            control={control}
            isRequired={false}
            dependencies={["instrument_type"]}
            watch={watch}
            disabled={
              formSupplementData?.drawdownDistributionNumber?.disabled ||
              !["PEFUND", "PE"].includes(getValues("instrument_type"))
            }
            minValue={0}
            maxValue={999999999}
            maxLength={5}
            name={"drawdown_distribution_number"}
            label={t("inputs.labels.drawdownDistributionNumber")}
          ></FormInputNumber>
        </Box>
        <Box sx={{ maxWidth: "100%" }}>
          <FormInputMulti
            options={formSupplementData?.connectedDocuments?.valueOptions}
            disabled={formSupplementData?.connectedDocuments?.disabled}
            control={control}
            isRequired={false}
            name={"connected_documents"}
            size="small"
            label={t("inputs.labels.connectedDocuments")}
          ></FormInputMulti>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputCheckbox
            label={t("inputs.labels.addEmail")}
            control={control}
            isRequired={false}
            disabled={formSupplementData?.addEmail?.disabled}
            name={"add_email"}
          ></FormInputCheckbox>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FormInputCheckbox
            label={t("inputs.labels.testTransaction")}
            control={control}
            isRequired={false}
            disabled={formSupplementData?.test?.disabled}
            name={"test_transaction"}
          ></FormInputCheckbox>
        </Box>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Button type="submit" variant="contained" form={formId}>
          {t("general.update")}
        </Button>
      </Box>
    </Paper>
  );
};

CreateTransactionForm.propTypes = {};

export default CreateTransactionForm;
