import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, CircularProgress, Typography } from "@mui/material";
import CenterContentWrapper from "containers/CenterContentWrapper";
import { useLocation } from "react-router";
import _ from "lodash";
export const RTKLazyQueryWrapper = ({
  children,
  query,
  queryParams,
  triggerKey,
  LoadingComponent,
  dataIsImmutable,
  fetchOnMount,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const key = pathname.split("/").pop() || pathname; // Same logic as in SomeComponent
  const triggerValue = useSelector(
    (state) => state.queryTriggers.triggers[triggerKey ?? key]
  );
  const [fetchData, { data, error, isLoading, isFetching }] = query;
  const queryParamsRef = useRef(queryParams);
  queryParamsRef.current = queryParams;

  useEffect(() => {
    // Trigger the query only if the trigger value has been set (non-null/undefined)
    if (triggerValue != null || fetchOnMount) {
      fetchData(queryParamsRef.current);
    }
    return () => {
      // Cleanup
    };
  }, [triggerValue, fetchData, fetchOnMount]);

  if (error) {
    if (error.status === 401) {
      return null;
    } else if (error.status === 503) {
      enqueueSnackbar(
        error?.message ??
          `We are sorry that the underlying portfolio system is failing! Lets hope they are able to fix it [${error?.status}]`,
        {
          variant: "error",
        }
      );
    } else {
      enqueueSnackbar(
        error?.message ?? `Error fetching data[${error?.status}]`,
        {
          variant: "error",
        }
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant={"body2"} color={"error"}>
          {typeof error === "string" ? error : "An error occured"}
        </Typography>
      </Box>
    );
  }
  if (isLoading || isFetching) {
    if (LoadingComponent) {
      return (
        <CenterContentWrapper>
          <LoadingComponent />
        </CenterContentWrapper>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return children(dataIsImmutable ? data : _.cloneDeep(data));
};

export default RTKLazyQueryWrapper;
