import React, { useState } from "react";
import { useSelector } from "react-redux";

//MATERIAL UI IMPORTS
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
//YS COMPONENTS IMPORT
import CompanyCard from "./CompanyCard";
import YSSwitchPlain from "../../../components/YSSwitchPlain";
// // ACTIONS IMPORTS
// //YS UTILITY FUNCTIONS
import { Typography } from "@mui/material";

import { useGetBankBalancesQuery } from "api/apiSlice";

import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useTranslation } from "react-i18next";

import Accounts from "./Accounts";
import Loans from "./Loans";
const Currencies = () => {
  const date = useSelector((state) => state.user.endDate);
  const [selected, setSelected] = useState(null);
  const [sort, setSort] = useState(false);
  const { t } = useTranslation();
  const bankQuuery = useGetBankBalancesQuery({
    endDate: date,
  });
  const clientCurrency = useSelector(
    (state) => state.user.chosen_client?.client_info_fa?.currency
  );
  const handleCurrencycardClick = (data) => {
    setSelected(data);
  };
  return (
    <RTKQueryWrapper query={bankQuuery}>
      {(data) => (
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: 1,
              }}
            >
              <Typography> {t("general.sortBy")}</Typography>
              <YSSwitchPlain
                leftValue={t("bank.alphabetically")}
                isChecked={!!sort}
                rightValue={t("bank.mv")}
                onClick={(e) => setSort((oldValue) => !oldValue)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} p={1}>
            <Grid container spacing={2}>
              {Array.isArray(data?.companies) &&
                data?.companies
                  ?.sort((a, b) =>
                    sort
                      ? b.bal_base - a.bal_base
                      : a.comp_name?.localeCompare(b.comp_name)
                  )
                  .map((company, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={3}
                        p={2}
                        key={index}
                        name={company.comp_name}
                        value={company.bal_base}
                      >
                        <CompanyCard
                          data={{
                            ...company,
                            title: company.comp_name,
                            balance: company.bal_base,
                            loans: data?.loans?.rows?.reduce(
                              (accumulator, current) =>
                                current.comp_name === company.comp_name
                                  ? accumulator + current.bal_base
                                  : accumulator,
                              0
                            ),
                          }}
                          clientCurrency={clientCurrency}
                          onClick={handleCurrencycardClick}
                          selected={!!selected}
                          isExpanded={selected}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
          {selected && (
            <Grid item xs={12} md={4} lg={3} p={2} key={selected.comp_name}>
              <CompanyCard
                data={{
                  ...selected,
                  title: selected.comp_name,
                  balance: selected.bal_base,
                  loans: data?.loans?.rows?.reduce(
                    (accumulator, current) =>
                      current.comp_name === selected.comp_name
                        ? accumulator + current.bal_base
                        : accumulator,
                    0
                  ),
                }}
                clientCurrency={clientCurrency}
                onClick={handleCurrencycardClick}
                selected={selected}
                isExpanded={!!selected}
              />
            </Grid>
          )}
          <Grid item xs={12} p={2}>
            <Typography variant="h5">{t("bank.accounts")} </Typography>
            <Accounts
              accounts={data?.accounts}
              selected={selected}
              filterValue={"comp_name"}
              comparator={selected?.comp_name}
            />
          </Grid>
          <Grid item xs={12} p={2}>
            <Typography variant="h5"> {t("bank.loans")}</Typography>
            <Loans
              loans={data?.loans}
              selected={selected}
              filterValue={"comp_name"}
              comparator={selected?.comp_name}
            />
          </Grid>
        </Grid>
      )}
    </RTKQueryWrapper>
  );
};

export default Currencies;
