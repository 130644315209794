import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { CardHeader, Collapse, Divider } from "@mui/material";
import { formatValues } from "../../../utils/functions";
import { useTranslation } from "react-i18next";
const CompanyCard = ({
  data,
  onClick,
  clientCurrency,
  selected,
  isExpanded,
}) => {
  const { balance, loans, title } = data;
  const { t } = useTranslation();
  return (
    <Card
      sx={{ width: "100%", cursor: "pointer", backgroundColor: "transparent" }}
      onClick={() => onClick(selected?.comp_name === title ? null : data)}
      elevation={8}
    >
      <CardHeader
        sx={{
          p: 1,
          textAlign: "center",
          bgcolor: "primary.main",
          color: "primary.contrastText",
        }}
        title={title}
        titleTypographyProps={{
          variant: "h6",
          sx:
            selected === title
              ? { color: "info.contrastText", backgroundColor: "info.main" }
              : {
                  color: "primary.contrastText",
                  backgroundColor: "primary.main",
                },
        }}
      />
      <Collapse in={!isExpanded || selected.comp_name === title}>
        <CardContent
          sx={{ p: 1, bgcolor: "info.main", color: "info.contrastText" }}
        >
          <Grid
            container
            color="palette.primary.main"
            justifyContent={"center"}
            textAlign={"center"}
            rowGap={1}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle">
                {t("financeTerms.accountBalance", { currency: clientCurrency })}
              </Typography>
              <Typography variant="h6">
                {formatValues(balance, "millions", "", 1)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal">
                <Stack direction={"row"} spacing={1}>
                  <Typography variant="caption">
                    {t("financeTerms.netLiquidity")}
                  </Typography>
                  <Typography variant="caption">
                    {formatValues(balance + loans, "millions")}
                  </Typography>
                </Stack>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle">
                {t("financeTerms.bankLoans", { currency: clientCurrency })}
              </Typography>
              <Typography variant="body1">
                {formatValues(loans, "millions", "", 1)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};
CompanyCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default CompanyCard;
