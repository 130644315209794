import React, { Fragment, memo } from "react";
import {
  Button,
  ClickAwayListener,
  Fade,
  LinearProgress,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { NewPresetButton } from "./NewPresetButton";
import { PresetListItem } from "./PresetListItem";
import {
  useGetPresetsQuery,
  useUpdatePresetMutation,
  useDeletePresetMutation,
  useCreatePresetMutation,
} from "api/apiSlice";
import { useSnackbar } from "notistack";
import { localGridReducer } from "./gridPresetReducer";
import { useTranslation } from "react-i18next";
const INITIAL_STATE = {
  name: "default",
  presets: [],
  newPresetLabel: "",
  isMenuOpened: false,
  menuAnchorEl: null,
  activeViewId: null,
};

export const GridPresetMenu = memo(({ gridId, apiRef }) => {
  const { data, error, isLoading } = useGetPresetsQuery({
    id: gridId,
  });
  const [createPresetTrigger] = useCreatePresetMutation();
  const [updatePresetTrigger] = useUpdatePresetMutation();
  const [deletePresetTrigger] = useDeletePresetMutation();
  const [state, dispatch] = React.useReducer(localGridReducer, INITIAL_STATE);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const createNewPreset = async () => {
    const presetValue = apiRef.current.exportState();
    const preset = {
      value: presetValue,
      label: state.newPresetLabel,
      order: data?.presets?.length + 1,
    };
    await createPresetTrigger({ preset: preset, tableId: gridId })
      .unwrap()
      .then((res) => {
        dispatch({ type: "setActivePreset", presetId: res.id });
        enqueueSnackbar(
          t(`notisstack.dataGrid.createPresetSuccess`, {
            status: res.status ?? "200",
          }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          t(`notisstack.dataGrid.createPresetError`, { error: err.message }),
          {
            variant: "error",
          }
        );
      });
  };
  const handlenewPresetLabelChange = (event) => {
    dispatch({ type: "setnewPresetLabel", label: event.target.value });
  };

  const handleDeleteView = async (presetId) => {
    await deletePresetTrigger({ presetId: presetId, id: gridId })
      .unwrap()
      .then((res) => {
        dispatch({ type: "setActivePreset", presetId: "" });
        enqueueSnackbar(
          t(`notisstack.dataGrid.deletePresetSuccess`, {
            status: res.status ?? "200",
          }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          t(`notisstack.dataGrid.deletePresetError`, { error: err.message }),
          {
            variant: "error",
          }
        );
      });
  };
  const updatePreset = async (presetId) => {
    const presetValue = apiRef.current.exportState();
    const preset = {
      value: presetValue,
      label: state.newPresetLabel,
      order: data?.presets?.length + 1,
    };
    await updatePresetTrigger({
      preset: preset,
      presetId: presetId,
      id: gridId,
    })
      .unwrap()
      .then((res) => {
        dispatch({ type: "setActivePreset", presetId: res.id });
      })
      .catch((err) => console.log(err));
  };

  const handleSetActivePreset = (presetId) => {
    apiRef.current.restoreState(
      data?.presets?.find((p) => p.id === presetId)?.value
    );
    dispatch({ type: "setActivePreset", presetId });
  };

  const handlePopperAnchorClick = (event) => {
    dispatch({ type: "togglePopper", element: event.currentTarget });
    event.stopPropagation();
  };

  const handleClosePopper = () => {
    dispatch({ type: "closePopper" });
  };

  const isnewPresetLabelValid = React.useMemo(() => {
    if (state.newPresetLabel.length === 0) {
      return false;
    }

    return Object.values(data.presets).every(
      (view) => view.label !== state.newPresetLabel
    );
  }, [data, state]);

  const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
  const popperId = canBeMenuOpened ? "transition-popper" : undefined;

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      dispatch({ type: "closePopper" });
    } else if (event.key === "Escape") {
      dispatch({ type: "closePopper" });
    }
  };

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(
        t(`notisstack.dataGrid.getPresetError`, { error: error?.message }),
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  React.useEffect(() => {
    const migrateLocalData = async (
      newPresetLabel,
      newPresetOrder,
      newPresetValue
    ) => {
      await createPresetTrigger({
        preset: {
          value: newPresetValue,
          label: newPresetLabel,
          order: newPresetOrder,
        },
        tableId: gridId,
      })
        .then(() => {
          enqueueSnackbar("Preset migrated to the database", {
            variant: "success",
          });
          return true;
        })
        .catch(() => {
          enqueueSnackbar("There was a problem migrating the preset", {
            variant: "error",
          });
          return false;
        });
    };
    if (localStorage.getItem(gridId)) {
      const item = JSON.parse(localStorage.getItem(gridId));
      const entries = Object.entries(item.views);
      let allMigratedSuccessfully = true;
      for (let [key, value] of entries) {
        const success = migrateLocalData(
          value.label,
          entries.indexOf([key, value]) + 1,
          value?.value
        );
        if (!success) {
          allMigratedSuccessfully = false;
          break; // Exit the loop if one of the migrations fails
        }
      }

      // If all migrations succeed, remove the main item from localStorage
      if (allMigratedSuccessfully) {
        localStorage.removeItem(gridId);
      }
    }
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <LinearProgress color="primary" />
      ) : (
        data && (
          <Fragment>
            <Button
              aria-describedby={popperId}
              type="button"
              size="small"
              id="custom-view-button"
              aria-controls={
                state.isMenuOpened ? "custom-view-menu" : undefined
              }
              aria-expanded={state.isMenuOpened ? "true" : undefined}
              aria-haspopup="true"
              onClick={handlePopperAnchorClick}
            >
              {t(`dataGrid.presets.openButton`, { count: data.presets.length })}
            </Button>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <Popper
                id={popperId}
                open={state.isMenuOpened}
                anchorEl={state.menuAnchorEl}
                role={undefined}
                transition
                placement="bottom-start"
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <MenuList
                        dense={true}
                        autoFocusItem={state.isMenuOpened}
                        id="custom-view-menu"
                        aria-labelledby="custom-view-button"
                        onKeyDown={handleListKeyDown}
                      >
                        {data.presets.map((preset) => (
                          <PresetListItem
                            key={preset.id}
                            preset={preset}
                            presetId={preset.id}
                            selected={preset.id === state.activePreset}
                            onDelete={handleDeleteView}
                            onSelect={handleSetActivePreset}
                          />
                        ))}
                      </MenuList>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </ClickAwayListener>
          </Fragment>
        )
      )}

      <NewPresetButton
        label={state.newPresetLabel}
        onLabelChange={handlenewPresetLabelChange}
        onSubmit={createNewPreset}
        onUpdate={updatePreset}
        isValid={isnewPresetLabelValid}
        presets={data?.presets ?? []}
      />
    </Fragment>
  );
});
