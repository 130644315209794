import React, { useState } from "react";
import { parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DatePicker } from "@mui/x-date-pickers-pro";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

import { getDate } from "../../utils/functions";
import { DatePresetButtonContainer } from "./DatePresetButtonContainer";
import { useTranslation } from "react-i18next";
export function DatepickerYS({
  callback,
  name,
  date,
  presets,
  onPresetClick,
  presetDateValue,
  localMinDate,
  onError,
}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const maxDate = useSelector((state) => state.user.maxDate);
  const minDate = useSelector((state) => state.user.minDate);
  const dateSelectionViews = useSelector(
    (state) => state.user.chosen_client.settings.date_selection_views
  );
  const onDateChange = (value) => {
    callback(getDate(value));
    setOpen(false);
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePresetButtonClick = (date) => {
    onPresetClick(getDate(date));
  };
  return (
    <Box
      aria-owns={anchorEl && presets ? "date-range-preset-menu" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={dateSelectionViews || ["year", "month", "day"]}
          label={t(`datepickers.labels.${name || ""}`)}
          open={open}
          format="MMM do, yyyy"
          disableMaskedInput
          maxDate={typeof maxDate === "string" ? parseISO(maxDate) : new Date()}
          minDate={localMinDate ? parseISO(localMinDate) : parseISO(minDate)}
          value={parseISO(date)}
          onClose={() => setOpen(false)}
          onChange={onDateChange}
          onAccept={onDateChange}
          closeOnSelect={false}
          onError={onError}
          slotProps={{
            textField: {
              readOnly: true,
              sx: {
                "& .MuiInput-root": {
                  color: "primary.contrastText",

                  // borderBottom: (theme) =>
                  //   `1px solid ${theme.palette.primary.contrastText}`,
                  "&:before": {
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.primary.contrastText}`,
                  },
                  "&:after": {
                    borderBottom: (theme) =>
                      `2px solid ${theme.palette.primary.light}`,
                  },
                  "&:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: (theme) =>
                      `2px solid ${theme.palette.primary.light}`,
                  },
                },

                svg: { color: "primary.contrastText" },
                input: {
                  color: "primary.contrastText",
                },
                label: { color: "primary.contrastText" },
              },

              onClick: () => setOpen((oldState) => !oldState),
              variant: "standard",
            },
          }}
        />
      </LocalizationProvider>
      {!!presets && (
        <Popover
          id="date-range-preset-menu"
          sx={{
            pointerEvents: "none",
            mt: "8px",
            zIndex: 1099,
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={() => setAnchorEl(null)}
        >
          <DatePresetButtonContainer
            datePresetOptions={presets}
            date={presetDateValue}
            onClick={handlePresetButtonClick}
          />
        </Popover>
      )}
    </Box>
  );
}
