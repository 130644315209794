import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: import.meta.env.MODE === "development",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["localStorage", "navigator"], // Use localStorage first, then browser language
      caches: ["localStorage"], // Cache the language in localStorage
    },
    divider: ".",
    defaultNS: "translation",

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      reloadResources: true,
    },
  });

export default i18n;
