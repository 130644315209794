import React, { useState } from "react";
import {
  Box,
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { AppBar } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ICONS } from "assets/Icons";
import { useMovieData } from "@mui/x-data-grid-generator";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { YS_DEFAULT_THEME } from "themes/YsTheme";
import { Tabs } from "components/Tabs";
import YSSwitchPlain from "components/YSSwitchPlain";

const NUMBER_FORMAT_OPTIONS = [
  "millions",
  "billions",
  "trillions",
  "thousands",
  "normal",
];

const NUMBER_DECIMAL_OPTIONS = [1, 2, 3, 4, 5];
export const ShowcaseComponentMui = ({ getValues }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [boolean, setBoolean] = useState(false);
  const data = useMovieData();
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const COLUMNS = [
    { field: "title", headerName: "Title", groupable: false, flex: 1 },
    {
      field: "gross",
      headerName: "Gross",
      type: "number",
      flex: 1,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
  ];
  return (
    <ThemeProvider theme={createTheme(YS_DEFAULT_THEME(getValues()))}>
      <Box sx={{ width: 1 }}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{ mr: 2 }}
            >
              {ICONS.menu()}
            </IconButton>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem> Bank</MenuItem>
              <MenuItem> Cash</MenuItem>
              <MenuItem> Analytics</MenuItem>
            </Menu>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Finance
            </Typography>
            <Button color="inherit">Login</Button>
          </Toolbar>
        </AppBar>
        <Tabs
          options={[
            { label: "Tab one", value: "", key: "0" },
            { label: "Tab two", value: "", key: "1" },
            { label: "Tab three", value: "", key: "2" },
          ]}
          id={"TestSettings"}
        ></Tabs>
        <Stack direction={"row"} sx={{ m: 1 }}>
          <Card sx={{ m: 2 }}>
            <CardHeader
              title={<Typography variant="h2"> Cardheader h2</Typography>}
              subheader={
                <Typography variant="h6"> Card subheader h6</Typography>
              }
            ></CardHeader>
            <CardContent sx={{ height: 400 }}>
              <DataGridPremium
                rows={data.rows}
                columns={COLUMNS}
                initialState={{
                  aggregation: {
                    model: {
                      gross: "sum",
                    },
                  },
                }}
                experimentalFeatures={{ aggregation: true }}
              />
            </CardContent>
            <CardActions>
              <Stack>
                <Box sx={{ m: 2 }}>
                  <Button sx={{ mx: 1 }} variant={"default"}>
                    {" "}
                    Button default
                  </Button>
                  <Button sx={{ mx: 1 }} variant={"outlined"}>
                    {" "}
                    Button outlined
                  </Button>
                  <Button sx={{ mx: 1 }} variant={"contained"}>
                    {" "}
                    Button contained
                  </Button>
                </Box>
                <Box sx={{ m: 2 }}>
                  <Button sx={{ mx: 1 }} color="secondary" variant={"default"}>
                    {" "}
                    Button default
                  </Button>
                  <Button sx={{ mx: 1 }} color="secondary" variant={"outlined"}>
                    {" "}
                    Button outlined
                  </Button>
                  <Button
                    sx={{ mx: 1 }}
                    color="secondary"
                    variant={"contained"}
                  >
                    {" "}
                    Button contained
                  </Button>
                </Box>
              </Stack>
            </CardActions>
          </Card>
          <Card sx={{ m: 2 }}>
            <CardHeader
              title={<Typography variant="h4"> Cardheader h4</Typography>}
              subheader={
                <Typography variant="subtitle1">
                  {"Card subheader subtitle1 "}
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Typography variant="body1"> {" body1"}</Typography>
              <Typography variant="body2"> {" body2"}</Typography>
              <Typography variant="caption"> {"caption"}</Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ m: 2 }}>
                <YSSwitchPlain
                  isChecked={boolean}
                  onClick={() => setBoolean((p) => !p)}
                  leftValue={"Left value"}
                  rightValue={"Right value"}
                ></YSSwitchPlain>

                <Alert severity="warning" title="WARNING">
                  {" "}
                  WARNING
                </Alert>
                <Alert severity="error" title="ERROR">
                  {" "}
                  ERROR
                </Alert>
                <Alert severity="success" title="SUCCESS">
                  {" "}
                  SUCCESS
                </Alert>
                <Alert severity="info" title="INFO">
                  INFO
                </Alert>
              </Box>
            </CardActions>
          </Card>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default ShowcaseComponentMui;
