import React, { Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
//MATERIAL UI IMPORTS
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import { usePasswordResetConfirmMutation } from "api/apiSlice";
import { useTranslation } from "react-i18next";
const initialState = {
  newPassword: "",
  newPasswordConfirmed: "",
};

const ForgottenPassword = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ defaultValues: initialState, mode: "onChange" });
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [passwordResetConfirm, { isLoading, isSuccess }] =
    usePasswordResetConfirmMutation();

  const onSubmit = (e) => {
    if (e.newPassword === e.newPasswordConfirmed && uid && token) {
      passwordResetConfirm({ uid, token, ...e });
    }
  };
  React.useEffect(() => {
    reset();
  }, [reset, navigate, isSuccess]);
  return (
    <Fragment>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" color="inherit">
        {isSuccess
          ? t("loginPage.resetPassswordSuccess")
          : t("loginPage.resetPasssword")}
      </Typography>
      {!isSuccess ? (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component={"form"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h6">
            {t("loginPage.resetPassswordConfirmInfo")}
          </Typography>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: true,
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&^])[A-Za-z\d@$!#%*?&^]{8,}$/,
                message: t("loginPage.newPasswordPatternInvalidMessage"),
              },

              maxLength: {
                value: 30,
                message: t("loginPage.newPasswordMaxLengthMessage"),
              },
              validate: (value, fieldState) => {
                return (
                  fieldState.newPassword === fieldState.newPasswordConfirmed ||
                  fieldState.newPasswordConfirmed === "" ||
                  t("loginPage.passwordsDoNotMatchMessage")
                );
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ mb: 2 }}
                {...field}
                autoFocus
                autoComplete="new-password"
                fullWidth
                label={t("loginPage.newPasswordLabel")}
                size="medium"
                type="password"
                helperText={error?.message}
                error={error !== undefined}
              />
            )}
          />
          <Controller
            control={control}
            name="newPasswordConfirmed"
            rules={{
              required: true,
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&^])[A-Za-z\d@$#!%*?&^]{8,}$/,
                message:
                  "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character [@ $ ! # % * ? & ^]",
              },
              maxLength: {
                value: 30,
                message: "Max length is 30",
              },
              validate: (value, fieldState) => {
                return (
                  fieldState.newPassword === fieldState.newPasswordConfirmed ||
                  fieldState.newPassword === "" ||
                  "Fields do not match"
                );
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                sx={{ mb: 2 }}
                {...field}
                fullWidth
                autoComplete="new-password"
                label="Confirm new password"
                size="medium"
                type="password"
                helperText={error?.message}
                error={error !== undefined}
              />
            )}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            name="submit"
            endIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
            disabled={!uid || !token || isLoading || !isValid}
          >
            <Typography variant="button">Submit new password</Typography>
          </Button>
        </Box>
      ) : null}

      {isSuccess && (
        <Button
          sx={{ mt: 2 }}
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => navigate("/login")}
        >
          {"Return to sign in page"}
        </Button>
      )}
    </Fragment>
  );
};
export default ForgottenPassword;
