import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Tabs as MuiTabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
export const Tabs = ({ options, onChange, id, value }) => {
  const [internalValue, setValue] = useState(
    options?.length > 0 ? options[0].value : null
  );
  const { t } = useTranslation();
  const handleChange = (e, newValue) => {
    if (onChange) {
      onChange(e, newValue);
    }
    setValue(newValue);
  };
  if (!Array.isArray(options) && options?.length > 0) {
    return null;
  }
  return (
    <Box sx={{ width: "100%", boxShadow: 1, position: "sticky" }}>
      <MuiTabs
        centered
        value={value ? value : internalValue}
        onChange={handleChange}
        aria-label={`${id}Tabs`}
        scrollButtons="auto"
      >
        {options?.map((item, index) => {
          return (
            <Tab
              value={item.value}
              sx={{ textTransform: "none" }}
              component={Link}
              to={`${item.value}`}
              label={t(`tabs.${item.label}`, { defaultValue: item.label })}
              icon={<Divider />}
              key={index}
            />
          );
        })}
      </MuiTabs>
    </Box>
  );
};

Tabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default Tabs;
