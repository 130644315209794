import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
export const FormInputAutoComplete = ({
  options = [],
  callback,
  watch,
  dependencies,
  name,
  control,
  label,
  size,
  disabled,
  freeSolo,
  isRequired,
}) => {
  const dependantValues = watch ? watch(dependencies) : null;
  const { t } = useTranslation();
  const matchFoundInArrays = (arr1, arr2) =>
    arr1.some((value1) => arr2.some((value2) => value1 === value2));
  const newOptions = options.filter((item) => {
    if (!dependantValues) return true;
    if (Array.isArray(item?.comparator) && Array.isArray(dependantValues)) {
      return matchFoundInArrays(item?.comparator, dependantValues);
    }
    return true;
  });
  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: "This field is required" } : {}}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => {
        const handleChange = (e, data, reason) => {
          if (callback) {
            callback(e, data, reason);
          }
          if (reason === "clear") {
            onChange(null);
            return;
          }
          onChange(data.value);
        };

        return (
          <Autocomplete
            size={size || "medium"}
            options={newOptions}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                const o = options.find((item) => option === item.value);
                return t(`inputs.options.${o?.label}`, {
                  defaultValue: o.label,
                });
              }
              if (option) {
                return t(`inputs.options.${option.label}`, {
                  defaultValue: option.label,
                });
              }
              return "";
            }}
            filterOptions={(allOptions, state) => {
              return allOptions?.filter((item) => {
                if (state.inputValue) {
                  return (
                    item.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase()) ||
                    item.value
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }
                return true;
              });
            }}
            freeSolo={freeSolo}
            openOnFocus
            isOptionEqualToValue={(option, value) => {
              return (
                option.value === value.value || option.value === field.value
              );
            }}
            renderOption={(props, option) => {
              return (
                <MenuItem {...props} key={Math.random() + option.label}>
                  {option.label}
                </MenuItem>
              );
            }}
            disabled={
              disabled || dependantValues
                ? dependantValues.reduce((previous, current) => {
                    return previous ? previous : !current;
                  }, false)
                : false
            }
            {...field}
            onChange={handleChange}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={ref}
                  error={!!error}
                  helperText={error?.message}
                  required={!!isRequired}
                  fullWidth
                  variant="outlined"
                  label={label}
                ></TextField>
              );
            }}
          ></Autocomplete>
        );
      }}
    />
  );
};
FormInputAutoComplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      value: PropTypes.any,
      comparator: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ),
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  callback: PropTypes.func,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInputAutoComplete;
