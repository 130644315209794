import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const PresetListItem = (props) => {
  const { preset, presetId, selected, onDelete, onSelect, ...other } = props;
  return (
    <MenuItem
      sx={{
        display: "flex",
        width: 1,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      selected={selected}
      onClick={() => onSelect(presetId)}
      {...other}
    >
      <Typography variant="button">{preset.label}</Typography>

      <IconButton
        edge="end"
        aria-label="delete"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(presetId);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </MenuItem>
  );
};

PropTypes.PresetListItem = {
  preset: PropTypes.object.isRequired,
  presetId: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
