import React from "react";
import Box from "@mui/material/Box";
import Tabs from "../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { generateDynamicRoutes } from "../routes/utils";
import PerformancePageDashboard from "containers/pages/performance/PerformancePageDashboard";
import TimeSeries from "containers/pages/performance/TimeSeries";
import PortfolioContribution from "containers/pages/performance/PortfolioContribution";
import PortfolioAnalysis from "containers/pages/performance/PortfolioAnalysis";
export const PerformancePageLayout = () => {
  const performanceTabs = useSelector(
    (state) => state.user.chosen_client?.settings?.performance_tabs
  );
  const userRoutes = generateDynamicRoutes(
    [
      {
        path: "dashboard",
        element: <PerformancePageDashboard />,
      },
      {
        path: "timeseries",
        element: <TimeSeries />,
      },
      {
        path: "portfolioContribution",
        element: <PortfolioContribution />,
      },
      {
        path: "portfolioAnalysis",
        element: <PortfolioAnalysis />,
      },
    ],
    performanceTabs
  );
  const element = useRoutes(userRoutes);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Tabs options={performanceTabs} id={"performance"}></Tabs>
      {element}
    </Box>
  );
};
export default PerformancePageLayout;
