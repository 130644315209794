import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
  Box,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { ICONS } from "../../assets/Icons";
export const FormInputColor = ({
  classes,
  name,
  control,
  label,
  size,
  isRequired,
  type,
  disabled,
  maxLength,
}) => {
  const [focus, setFocus] = useState(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "Is required",
        pattern: {
          value: /^#([0-9A-F]{3}){1,2}$/i,
          message: "Invalid hex color code",
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange } = field;

        const handleBlur = () => {
          setFocus(false);
        };
        const handleColorPick = (e) => {
          onChange(e.hex);
        };
        return (
          <ClickAwayListener onClickAway={() => setFocus(false)}>
            <Box>
              <TextField
                className={classes?.formInput}
                disabled={disabled}
                onFocus={() => setFocus(true)}
                onBlur={handleBlur}
                variant={"outlined"}
                type={type || "text"}
                fullWidth
                required={!!isRequired}
                size={size ? size : "small"}
                error={!!error}
                helperText={error?.message}
                label={label}
                autoComplete={"off"}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {ICONS.color({ sx: { color: field.value } })}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {focus ? (
                <Box>
                  <SketchPicker
                    color={field.value}
                    // onChangeComplete={handleColorPick}
                    onChange={handleColorPick}
                  ></SketchPicker>
                </Box>
              ) : null}
            </Box>
          </ClickAwayListener>
        );
      }}
    />
  );
};

FormInputColor.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInputColor;
