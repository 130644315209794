import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGridTable } from "components/Tables/DataGridTable";
import { RTKQueryWrapper } from "components/DataFetchWrapper/RTKQueryWrapper";
import { useGetSubscriptionsPerClientQuery } from "api/apiSlice";
import CreateSubscriptionModal from "./CreateSubscriptionModal";

const Subscriptions = () => {
  const { endDate } = useSelector((state) => state.user);
  const { data, refetch, ...query } = useGetSubscriptionsPerClientQuery(
    { endDate: endDate },
    { skip: !endDate }
  );
  const [open, setOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    console.log("Fetched subscriptions:", data);
  }, [data]);

  const handleOpen = () => {
    setSelectedSubscription(null); // Clear previous selection
    setOpen(true);
  };  

  const handleClose = () => setOpen(false);

  const handleRowClick = (params) => {
    const row = params.row || params;
    setSelectedSubscription(row);
    setOpen(true);
  };

  const processData = (data) => {
    if (!data || !Array.isArray(data.rows)) return [];
    return data.rows.map((row) => ({
      ...row,
      client: row.client?.display_name || "unknown", // Show client display name instead of object
      data_quality_check: row.data_quality_check?.name || "unknown", 
      parameters: JSON.stringify(row.parameters), // Convert parameters object to a string for display
      run_time: row.run_time || "N/A", // Add run_time for display
      days_to_run: row.days_to_run?.join(", ") || "N/A", // Format days to run
    }));
  };

  return (
    <Box sx={{ height: "83vh", width: "100%", p: 2 }}>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add New Subscription
      </Button>
      <RTKQueryWrapper query={{ ...query, data }}>
        {(data) => (
          <DataGridTable
            rows={processData(data)}
            columns={[
              { field: "client", headerName: "Client", width: 150 }, // Fixed client field
              { field: "data_quality_check", headerName: "Check Name", width: 200 },
              { field: "parameters", headerName: "Parameters", width: 250 }, // Fixed parameters field
              { field: "run_time", headerName: "Run Time", width: 150 }, 
              { field: "days_to_run", headerName: "Days to Run", width: 200 }
            ]}
            autoPageSize={true}
            id={"SubscriptionsTable"}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        )}
      </RTKQueryWrapper>

      <CreateSubscriptionModal
        open={open}
        handleClose={handleClose}
        refetch={refetch}
        subscription={selectedSubscription}
      />
    </Box>
  );
};

export default Subscriptions;