import React, { Fragment } from "react";
import { useSelector } from "react-redux";
//imports material icons
//imports YS components
import { Tabs } from "components/Tabs/Tabs";
// Redux actions
import { useGetInvestorPortfoliosQuery } from "api/apiSlice";
// YS Componentns

import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useNavigate, Outlet, useParams } from "react-router-dom";
export const MyHoldingsLayout = () => {
  const navigate = useNavigate();
  const date = useSelector((state) => state.user.endDate);
  const { portfolioId } = useParams();
  const investorPortfoliosQuery = useGetInvestorPortfoliosQuery({
    endDate: date,
  });
  React.useEffect(() => {
    if (investorPortfoliosQuery.isSuccess && !portfolioId) {
      navigate(investorPortfoliosQuery.data[0]?.id.toString());
    }
  }, [
    investorPortfoliosQuery.data,
    investorPortfoliosQuery.isSuccess,
    navigate,
    portfolioId,
  ]);
  return (
    <Fragment>
      <RTKQueryWrapper query={investorPortfoliosQuery}>
        {(tabData) => (
          <Tabs
            value={portfolioId}
            options={tabData.map((item, idx) => {
              return {
                key: item.id.toString(),
                value: item.id.toString(),
                label: item.name,
              };
            })}
            id={"MyHoldingsTabs"}
          />
        )}
      </RTKQueryWrapper>
      <Outlet />
    </Fragment>
  );
};

export default MyHoldingsLayout;
