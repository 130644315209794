import React from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";

import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
export const FormInputCheckBox = ({
  name,
  control,
  label,

  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <FormGroup
              aria-labelledby="form-controlled-checkbox-group"
              {...field}
            >
              <FormControlLabel
                disabled={disabled}
                err
                control={
                  <Checkbox
                    error={error}
                    checked={field.value}
                    onChange={field.onChange}
                  />
                }
              />
            </FormGroup>
            <FormLabel id={"form-controlled-checkbox-group"}>{label}</FormLabel>
          </Box>
        );
      }}
    />
  );
};

FormInputCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};
export default FormInputCheckBox;
