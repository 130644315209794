import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { loadFlag } from "utils/getFlagByCurrency";

const Flag = ({ currencyCode }) => {
  const [flagSrc, setFlagSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFlag = async () => {
      setLoading(true);
      const src = await loadFlag(currencyCode);
      setFlagSrc(src);
      setLoading(false);
    };

    fetchFlag();
  }, [currencyCode]);

  if (loading) return <CircularProgress />; // or you can return a placeholder/loading spinner here

  return <img src={flagSrc} alt={currencyCode} />;
};

export default Flag;
