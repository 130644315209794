import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { DataGridTable } from "components/Tables/DataGridTable";
import { useGetBankBalancesQuery } from "api/apiSlice";
import { RTKQueryWrapper } from "components/DataFetchWrapper";
const AccountsTable = () => {
  const endDate = useSelector((state) => state.user.endDate);
  const bankQuuery = useGetBankBalancesQuery({
    endDate,
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <RTKQueryWrapper query={bankQuuery}>
          {(data) => (
            <Box sx={{ height: "60vh", p: 2 }}>
              <DataGridTable {...data?.loans} id={"bankAccounts"} />
            </Box>
          )}
        </RTKQueryWrapper>
      </Grid>
    </Grid>
  );
};

export default AccountsTable;
