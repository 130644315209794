import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { CardHeader, Collapse, Divider, Box } from "@mui/material";
import { formatCurrency, formatValues } from "../../../utils/functions";
import { useSelector } from "react-redux";
import Flag from "components/Flag/Flag";
import { useTranslation } from "react-i18next";

export const CurrencyBox = (props) => {
  const {
    data: {
      name,
      code,
      marketValueLocal,
      marketValue,
      shareOfTotal,
      marketFxRate,
      hedges,
      underlying,
    },
    selected,
    setSelectedCurrency,
  } = props;
  const clientCurrency = useSelector(
    (store) => store.user?.chosen_client?.client_info_fa?.currency
  );
  const { t } = useTranslation();
  const handleCardClick = () => {
    setSelectedCurrency(props.data);
  };
  return (
    <Card
      sx={{ width: "100%", cursor: "pointer", backgroundColor: "transparent" }}
      onClick={handleCardClick}
      elevation={8}
    >
      <CardHeader
        sx={{
          p: 1,
          textAlign: "center",
          bgcolor: "primary.main",
          color: "primary.contrastText",
        }}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Flag currencyCode={code} />
            <Typography variant="h5">{name}</Typography>
            <Typography variant="h6">
              {formatValues(shareOfTotal, "percent", "", 1, 1)}
            </Typography>
          </Box>
        }
        titleTypographyProps={{
          sx:
            selected?.name === name
              ? { color: "info.contrastText", backgroundColor: "info.main" }
              : {
                  color: "primary.contrastText",
                  backgroundColor: "primary.main",
                },
        }}
      />
      <Collapse in={selected?.name === name || !selected}>
        <CardContent
          sx={{ p: 1, bgcolor: "info.main", color: "info.contrastText" }}
        >
          <Grid
            container
            color="palette.primary.main"
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    variant="subtitle"
                  >
                    {t("financeTerms.netValue", {
                      clientCurrency: clientCurrency,
                    })}
                  </Typography>
                  <Typography variant="h6">
                    {formatCurrency(marketValue, clientCurrency, 2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    variant="subtitle"
                  >
                    {t("financeTerms.netValueLocal", { currency: code })}
                  </Typography>

                  <Typography variant="h6">
                    {formatCurrency(marketValueLocal, code, 2)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal">
                <Typography variant="caption">
                  {`${code}/${clientCurrency} ` + marketFxRate?.toFixed(2)}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    variant="subtitle"
                  >
                    {t("financeTerms.underlying")}
                  </Typography>
                  <Typography whiteSpace={"nowrap"} variant="h6">
                    {formatCurrency(underlying, clientCurrency, 2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    variant="subtitle"
                  >
                    {t("financeTerms.hedges")}
                  </Typography>
                  <Typography whiteSpace={"nowrap"} variant="h6">
                    {formatCurrency(hedges, clientCurrency, 2)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};
CurrencyBox.propTypes = {
  data: PropTypes.object,
};

export default CurrencyBox;
