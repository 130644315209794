import React, { useCallback, memo } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CustomToolbar from "./CustomToolBar";
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  useGridApiRef,
  nbNO,
} from "@mui/x-data-grid-premium";
import { useDataGridColumns } from "./hooks/useDataGridColumns";
import {
  getTableState,
  saveTableState,
  CUSTOM_AGGREGATION_FUNCTIONS,
} from "./utils";
import { useTranslation } from "react-i18next";

export const DataGridTable = ({
  gridRef,
  rows,
  columns,
  aggregationModel,
  filterModel,
  columnVisibilityModel,
  defaultGroup,
  selectable,
  searchable,
  groupingColDef,
  onCellClick,
  onCellDoubbleClick,
  onRowClick,
  autoPageSize,
  autoHeight,
  pageSize,
  hideFooter,
  disableVirtualization,
  id,
  ...props
}) => {

  const defaultRef = useGridApiRef();
  const apiRef = gridRef || defaultRef;
  const { processedColumns } = useDataGridColumns(columns);

  const { i18n } = useTranslation();
  const initial_state = React.useMemo(
    () => ({
      aggregation: {
        model: aggregationModel
          ? aggregationModel
          : columns?.reduce((previous, current) => {
              if (current.aggregation) {
                return {
                  ...previous,
                  [current.field]: current.aggregation,
                };
              }
              return { ...previous };
            }, {}),
      },
      filter: {
        filterModel: {
          items: filterModel ? filterModel : [],
        },
      },
      rowGrouping: {
        model: defaultGroup ? defaultGroup : [],
      },
      columns: {
        columnVisibilityModel: columnVisibilityModel
          ? columnVisibilityModel
          : columns?.reduce((previous, current) => {
              if (typeof current.hide === "boolean") {
                return {
                  ...previous,
                  [current.field]: !current.hide,
                };
              }
              return { ...previous };
            }, {}),
      },
      sorting: { sortModel: [] },
    }),
    [
      aggregationModel,
      filterModel,
      defaultGroup,
      columnVisibilityModel,
      columns,
    ]
  );
  const handleCellClick = useCallback(
    (e, v) => {
      if (onCellClick) {
        onCellClick(e, v);
      }
    },
    [onCellClick]
  );
  const handleCellDoubbleClick = useCallback(
    (e, v) => {
      if (onCellDoubbleClick) {
        onCellDoubbleClick(e, v);
      }
    },
    [onCellDoubbleClick]
  );
  const handleRowDoubbleClick = useCallback(() => {
    console.log("row double click");
  }, []);

  const onError = (e) => {
    console.log(e);
  };

  const saveSnapshot = React.useCallback(() => {
    const currentApiRef = apiRef?.current;
    const sessionState = getTableState(id);
    if (sessionState && currentApiRef) {
      const exportedState = currentApiRef.exportState();
      saveTableState(exportedState, id);
    }
  }, [apiRef, id]);

  React.useLayoutEffect(() => {
    const currentApiRef = apiRef?.current;
    const sessionState = getTableState(id);
    if (sessionState && currentApiRef) {
      currentApiRef.restoreState(sessionState);
    }
    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot, apiRef, id]);

  return (
    <Paper sx={{ display: "flex", height: "100%", p: 0, m: 0 }}>
      <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <DataGridPremium
          localeText={
            i18n.language === "no"
              ? nbNO.components.MuiDataGrid.defaultProps.localeText
              : undefined
          }
          onCellClick={handleCellClick}
          onRowClick={onRowClick}
          onError={onError}
          apiRef={apiRef}
          density={"compact"}
          autoPageSize={autoPageSize}
          autoHeight={autoHeight}
          pageSize={pageSize}
          initialState={initial_state}
          checkboxSelection={selectable || false}
          groupingColDef={groupingColDef}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            ...CUSTOM_AGGREGATION_FUNCTIONS,
          }}
          columns={processedColumns}
          rows={
            rows?.map((item, index) => {
              console.log("Rows in DataGridPremium:", rows); // Debugging rows before passing
              if (item.id) {
                return item;
              } else {
                item.id = index;
                return item;
              }
            }) || []
          }
          onRowSelectionModelChange={(ids) => {
            console.log("Selected IDs:", ids); // Debugging selected rows
            if (props.onSelectionModelChange) {
              props.onSelectionModelChange(ids); // Call parent handler if provided
            } else {
              console.warn("No onSelectionModelChange handler passed as a prop.");
            }
          }}
          
          slots={{
            toolbar: (params) => <CustomToolbar {...params} />,
          }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              searchable: searchable || false,
              gridId: id,
              initial_state,
            },
          }}
          disableVirtualization={disableVirtualization}
          hideFooterRowCount={true}
          hideFooter={hideFooter}
          onRowDoubleClick={handleRowDoubbleClick}
          onCellDoubleClick={handleCellDoubbleClick}
          autosizeOptions={{ expand: true }}
          autosizeOnMount={true}
          {...props}
        ></DataGridPremium>
      </Box>
    </Paper>
  );
};

DataGridTable.propTypes = {
  gridRef: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  aggregationModel: PropTypes.object,
  filterModel: PropTypes.arrayOf(PropTypes.object),
  columnVisibilityModel: PropTypes.object,
  defaultGroup: PropTypes.arrayOf(PropTypes.string),
  selectable: PropTypes.bool,
  searchable: PropTypes.bool,
  groupingColDef: PropTypes.object,
  onCellClick: PropTypes.func,
  onCellDoubbleClick: PropTypes.func,
  autoPageSize: PropTypes.bool,
  autoHeight: PropTypes.bool,
  pageSize: PropTypes.number,

  id: PropTypes.string.isRequired,
};
export default DataGridTable;
