import React from "react";
import { Box } from "@mui/material";
import { Tabs } from "components/Tabs/Tabs";
import { Outlet, useLocation } from "react-router-dom";

const HealthCheckLayout = () => {
  const location = useLocation();

  // Define your base tabs with the new "Run Check" tab
  const tabs = [
    { key: "1", label: "Dashboard", value: "dashboard" },
    { key: "2", label: "Run Check Manually", value: "run_check" },  
    { key: "3", label: "Subscriptions", value: "subscriptions" },
    { key: "4", label: "TWR Check", value: "timeseries" },
  ];

  // Check if the URL matches the check_result page and dynamically add the tab
  const isCheckResultPage = location.pathname.includes("check_result");
  if (isCheckResultPage) {
    tabs.push({
      key: "5",
      label: "Check Result",
      value: `check_result/${location.pathname.split('/').pop()}`,  // Extract ID from URL
    });
  }

  const currentTab = tabs.findIndex(tab => location.pathname.includes(tab.value));

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Tabs
        id={"HealthCheck"}
        options={tabs.map(tab => ({
          key: tab.key,
          label: tab.label,
          value: `/app/fa_doctor/${tab.value}`
        }))}
        value={currentTab !== -1 ? `/app/fa_doctor/${tabs[currentTab].value}` : ""}
      />
      <Outlet />
    </Box>
  );
};

export default HealthCheckLayout;
