import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authEndpoints } from "./authEndpoints";
import { userEndpoints } from "./userEndpoints";
import { dataEndpoints } from "./dataEndpoints";
import { clientEndpoints } from "./clientEndpoints";
import { liquidityEndpoints } from "./liquidity/liquidityEndpoints";
import { tablePresetEndpoints } from "./appStateManager/tablePresetEndpoints";
import { performanceEndpoints } from "./performance/performanceEndpoints";
import { allocationsEndpoints } from "./allocations/allocationsEndpoints";
import { peDocumentsEndpoints } from "./documentProcessing/documentProcessingEndpoints";
import { managementEndpoints } from "./managementEndpoints";

export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const { getState } = api;
    const state = getState();
  
    // Initialize url variable
    let url;
  
    // Ensure args is defined and properly formatted
    if (typeof args === 'string') {
      url = args;
      args = { url };
    } else if (args && typeof args === 'object' && args.url) {
      url = args.url;
    } else {
      console.error("Error: 'args' is undefined or 'args.url' is missing in baseQuery.");
      return { error: { status: 'CUSTOM_ERROR', data: 'Invalid arguments in baseQuery' } };
    }
  
    // Safely access state.queryTriggers and triggers
    const firstTrigger = state.queryTriggers?.triggers
      ? Object.values(state.queryTriggers.triggers)[0] // Grab the first object
      : undefined;
    const stateExtraOptions = firstTrigger?.extraOptions || {};
  
    // Apply extraOptions logic if stateExtraOptions.refresh is true
    if (stateExtraOptions.refresh) {
      try {
        const urlObj = new URL(url, import.meta.env.VITE_API_URL);
  
        if (!urlObj.searchParams.has('update')) {
          urlObj.searchParams.append('update', 'true');
        }
  
        url = urlObj.toString();
      } catch (e) {
        console.error("Error constructing URL in baseQuery:", e);
        return { error: { status: 'CUSTOM_ERROR', data: 'Invalid URL in baseQuery' } };
      }
    }
  
    // Prepare the base fetch
    const baseFetch = fetchBaseQuery({
      baseUrl: import.meta.env.VITE_API_URL,
      prepareHeaders: (headers, { getState }) => {
        const token = getState()?.auth?.token;
        if (token) {
          headers.set("Authorization", `Token ${token}`);
        }
        return headers;
      },
    });
  
    // Build the final args for baseFetch
    const baseFetchArgs = { ...args, url };
  
    // Call the base fetch
    return baseFetch(baseFetchArgs, api, extraOptions);
  },

  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...userEndpoints(builder),
    ...dataEndpoints(builder),
    ...clientEndpoints(builder),
    ...liquidityEndpoints(builder),
    ...tablePresetEndpoints(builder),
    ...performanceEndpoints(builder),
    ...allocationsEndpoints(builder),
    ...peDocumentsEndpoints(builder),
    ...managementEndpoints(builder),
  }),
});

// Automatically generated hooks
export const {
  // Auth endpoints
  useLoginMutation,
  usePasswordChangeMutation,
  usePasswordResetMutation,
  usePasswordResetConfirmMutation,
  useGetBrandingQuery,
  // User endpoints
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateUserSettingsMutation,
  // Data endpoints
  useGetPositionsDataQuery,
  useGetDirectInvestmentsDataQuery,
  useGetDocumentListQuery,
  useGetTransactionsOptionsQuery,
  useUpdateDocumentMutation,
  useGetDocumentArchiveQuery,
  useGetExcelDownloadsOptionsQuery,
  useGetTransactionsOverviewQuery,
  useGetAssetClassPieQuery,
  useGetTotalMarketValueAndReturnQuery,
  useGetNavBridgeQuery,
  useGetBankBalancesQuery,
  useGetAssetAllocationQuery,
  useGetTotalMarketValueAndReturnCustomQuery,
  useGetWaterfallQuery,
  useGetTimeSeriesMonthlyQuery,
  useGetTimeSeriesDailyQuery,
  useGetSecurityReturnsQuery,
  useGetAllocationAssetClassQuery,
  useGetAllocationSectorQuery,
  useGetTopBottomContributorsQuery,
  useGetNetCashFlowQuery,
  useGetNewAttributionQuery,
  useGetEntityChartQuery,
  useGetLiquidityPageQuery,
  useGetExternalMandatesQuery,
  useGetCurrenciesExposureDetailQuery,
  useGetCurrencyExposureChartQuery,
  useGetCurrencyExposureChartUnhedgedQuery,
  useGetAeternumAnnualReturnQuery,

  useGetAllocationBoxesDataQuery,
  useGetSecurityTypeChartQuery,
  useGetContributionSectorTableQuery,
  //Investor endpoints
  useGetInvestorComponentQuery,
  useGetInvestorPortfoliosQuery,
  useGetReturnContributionTableQuery,
  //SuperGet endpoints
  /**
   * @Todo To be removed after the new endpoints are implemented
   */
  useGetCustomClientDataQuery,
  useGetPrivateEquityDataQuery,
  useGetXrayDataQuery,
  // Performance and Contribution endpoints
  useGetPerformanceTextBoxesQuery,
  useLazyGetPerformanceTextBoxesQuery,
  useGetPerformanceContributionQuery,
  useLazyGetPerformanceContributionQuery,
  useGetPerformanceDrilldownQuery,
  useLazyGetPerformanceDrilldownQuery,
  useGetPerformancePeriodBarChartQuery,
  useLazyGetPerformancePeriodBarChartQuery,
  useGetPerformanceTableQuery,
  useLazyGetPerformanceTableQuery,
  useGetPerformanceTimeSeriesChartQuery,
  useLazyGetPerformanceTimeSeriesChartQuery,
  useGetPerfTwrContrVsVolChartQuery,
  useLazyGetPerfTwrContrVsVolChartQuery,
  useGetReturnBoxesQuery,
  useLazyGetReturnBoxesQuery,
  useLazyGetContributionSectorTableQuery,
  useGetAnalyticsOptionsQuery,
  // Client endpoints
  useChangeClientMutation,
  useUpdateClientSettingsMutation,
  useGetQandAQuery,
  useUpdateQandAMutation,
  useCreateQandAMutation,
  useDeleteQandAMutation,
  // Performance endpoints

  // Liquidity endpoints
  useGetLiquidityTableQuery,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
  useCreateTransactionMutation,
  // Table preset endpoints
  useGetPresetsQuery,
  useCreatePresetMutation,
  useUpdatePresetMutation,
  useDeletePresetMutation,

  // Private Equity Documents endpoints
  useGetPeDocumentsQuery,
  useUpdatePeDocumentMutation,

  useGetPeDocumentTransactionsQuery,
  useCreatePeTransactionMutation,
  useUpdatePeTransactionMutation,
  useDeletePeTransactionMutation,
  useImportCreatedPeTransactionsMutation,
  // Allocations endpoints
  useGetAllocationsDynamicPieChartsQuery,
  useLazyGetAllocationsDynamicPieChartsQuery,
  // FA Doctor endpoints
  useGetDataQualityChecksPerClientQuery,
  useDeletePerformedChecksMutation,
  useUpdatePerformedChecksMutation,
  useGetHtmlContentTWRQuery,
  useGetSubscriptionsPerClientQuery,
  useGetDataQualityChecksQuery,
  useCreateDataQualitySubscriptionMutation,
  useUpdateDataQualitySubscriptionMutation,
  useDeleteDataQualitySubscriptionMutation,
  useGetDataQualityCheckByIdQuery,
  useRunDataQualityPerformedCheckQuery,
  useRunDataQualityPerformedCheckMutation,
  useUpdateCheckFailAcceptedMutation,
  // User managements endpoints
  useGetClientUserListQuery,
  useGetClientUserDetailsQuery,
  useCreateClientUserMutation,
  useUpdateClientUserMutation,
  useDeleteClientUserMutation,
  useGetCreateUserOptionsQuery,
  useGetClientDetailsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetClientListQuery,
  useGetClientCutoffDateQuery,
  useUpdateClientCutoffDateMutation,
} = api;
