import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
} from "@mui/material";
const ConfirmRowEditDialog = ({
  apiRef,
  noButtonRef,
  promiseArguments,
  handleNo,
  handleYes,
  handleEntered = () => undefined,
}) => {
  const { oldRow = null, newRow = null } = promiseArguments || {};
  const visibleColumns = apiRef?.current?.getVisibleColumns();
  return (
    <Dialog
      maxWidth="sm"
      TransitionProps={{ onEntered: handleEntered }}
      open={oldRow !== null && newRow !== null}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        {oldRow?.isNew
          ? `Pressing 'Yes' will create a new row with the following values:`
          : `Pressing 'Yes' will change following:`}
        {visibleColumns?.map((item, idx) => {
          if (item.editable === false) return null;
          const isDiff = oldRow[item.field] !== newRow[item.field];
          return (
            <Box key={idx} sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight={"bold"} variant="subtitle">
                  {`${item.headerName}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                {isDiff ? (
                  <Fragment>
                    <Box flexGrow={1} width={"50%"}>
                      <Typography fontStyle={"oblique"} color={"error.main"}>
                        {oldRow[item.field]?.toString()}
                      </Typography>
                    </Box>
                    <Box flexGrow={1} width={"50%"}>
                      <Typography color="success.main">
                        {newRow[item.field]?.toString()}{" "}
                      </Typography>
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Box flexGrow={1} width={"50%"}>
                      <Typography fontStyle={"oblique"} color="text">
                        {oldRow[item.field]?.toString()}
                      </Typography>
                    </Box>
                    <Box flexGrow={1} width={"50%"}>
                      <Typography color="text">
                        {newRow[item.field]?.toString()}{" "}
                      </Typography>
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Divider />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" ref={noButtonRef} onClick={handleNo}>
          No
        </Button>
        <Button variant="contained" onClick={handleYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRowEditDialog;
