import {
  GridToolbarContainer,
  useGridApiContext,
  GridRowModes,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";
import { ICONS } from "../../../../assets/Icons";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

const EditToolbar = ({ setRowModesModel, setRows, rowModesModel, showAddRowButton = true }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const handleAddRow = () => {
    const id = v4();
    setRows((oldRows) => [{ id: id, isNew: true }, ...oldRows]);

    const focusField = apiRef.current.getVisibleColumns()[0]?.field;
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, cellToFocus: focusField },
    });
    apiRef.current.setCellFocus(id, focusField);
  };

  return (
    <GridToolbarContainer>
      {showAddRowButton && (
        <Button color="primary" startIcon={ICONS.add()} onClick={handleAddRow}>
          {t("dataGrid.toolbarButtons.addRow")}
        </Button>
      )}
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

EditToolbar.propTypes = {};

export default EditToolbar;