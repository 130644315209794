import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { RTKQueryWrapper } from "components/DataFetchWrapper/RTKQueryWrapper";
import { useGetHtmlContentTWRQuery } from "api/apiSlice";

const TWRCheck = () => {
  const { endDate } = useSelector((state) => state.user);
  const { data, refetch, ...query } = useGetHtmlContentTWRQuery(
    { endDate: endDate },
    { skip: !endDate }
  );
  console.log(data)

  return (
    <Box sx={{ height: "83vh", width: "100%", p: 2 }}>
      <Button variant="contained" color="primary" onClick={() => refetch()}>
        Refetch Data
      </Button>
      <RTKQueryWrapper query={{ ...query, data }}>
        {(data) => (
          <Box
            sx={{ mt: 2, overflow: 'auto' }}  
            dangerouslySetInnerHTML={{ __html: data || "" }}
          ></Box>
        )}
      </RTKQueryWrapper>
    </Box>
  );
};

export default TWRCheck;
