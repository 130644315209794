import React from "react";
import { useSelector } from "react-redux";

// MUI
import Grid from "@mui/material/Grid";

import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { KeyDataCard } from "../../../components/YSCards";

// ACTIONS IMPORTS
import { ControlledPlotlyChart } from "../../../components/PlotlyChart";
import {
  useLazyGetPerformanceTextBoxesQuery,
  useLazyGetPerformanceTimeSeriesChartQuery,
} from "api/apiSlice";
import { RTKLazyQueryWrapper } from "components/DataFetchWrapper";
export const PerformancePage = () => {
  const startDate = useSelector((state) => state.user.presetStartDate);
  const endDate = useSelector((state) => state.user.presetEndDate);
  const performanceQuery = useLazyGetPerformanceTextBoxesQuery();
  const performanceTimeSeriesChartQuery =
    useLazyGetPerformanceTimeSeriesChartQuery();

  return (
    <Grid container p={2}>
      <Grid item xs={12} md={4} xl={4} justifyContent={"center"}>
        <RTKLazyQueryWrapper
          query={performanceQuery}
          fetchOnMount
          queryParams={{ startDate, endDate }}
        >
          {(data) => (
            <KeyDataCard
              containerProps={{
                sx: {
                  justifyContent: "center",
                },
              }}
              data={data}
            />
          )}
        </RTKLazyQueryWrapper>
      </Grid>

      <Fade in={true} timeout={2000}>
        <Grid
          item
          xs={12}
          md={8}
          xl={8}
          px={{ xs: 0, md: 2 }}
          py={{ xs: 2, md: 0 }}
        >
          <Card>
            <CardContent>
              <RTKLazyQueryWrapper
                query={performanceTimeSeriesChartQuery}
                queryParams={{ startDate, endDate }}
                fetchOnMount
              >
                {(data) => <ControlledPlotlyChart data={data} />}
              </RTKLazyQueryWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default PerformancePage;
