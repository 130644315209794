import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LoginLayout from "../layouts/LoginLayout";
import Login from "containers/pages/auth/Login";
import ResetPassword from "../containers/pages/auth/ResetPassword";
import ResetPasswordConfirm from "../containers/pages/auth/ResetPasswordConfirm";
import React from "react";

const LoginRoutes = () => {
  const LOGIN_ROUTES = [
    {
      path: "login",
      element: <LoginLayout />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "reset",
          element: <ResetPassword />,
        },
        {
          path: "resetConfirm/:uid/:token",
          element: <ResetPasswordConfirm />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={"/login"} />,
    },
  ];

  return useRoutes(LOGIN_ROUTES);
};

export default LoginRoutes;
