import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { isEmpty } from "utils/functions";
const PresetActionDialog = (props) => {
  const {
    label,
    onLabelChange,
    onSubmit,
    isValid,
    presets,
    onUpdate,
    isAddingPreset,
    setIsAddingPreset,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [presetToUpdate, setPresetToUpdate] = React.useState("");
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (presetToUpdate) {
      onUpdate(presetToUpdate); // Here, pass the necessary parameters to update the preset.
    } else {
      onSubmit(); // Submit will create a new preset.
    }
    setIsAddingPreset(false);
  };
  const handlePresetSelectChange = (e) => {
    const selectedValue = e?.target?.value;
    if (!isEmpty(selectedValue)) {
      setPresetToUpdate(selectedValue);
    } else {
      setPresetToUpdate("");
      enqueueSnackbar("Selected item is missing id", { variant: "info" });
    }
  };
  return (
    <Dialog onClose={() => setIsAddingPreset(false)} open={isAddingPreset}>
      <form onSubmit={handleSubmitForm}>
        <DialogTitle>New custom view</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={label}
            onChange={onLabelChange}
            margin="dense"
            size="small"
            label="Custom preset label"
            variant="standard"
            fullWidth
          />
          <FormControl
            fullWidth
            variant="outlined"
            margin="dense"
            sx={{ minWidth: 120, maxWidth: "100%" }}
          >
            <InputLabel id="preset-label">Existing Preset</InputLabel>
            <Select
              labelId="preset-label"
              label="Existing Preset"
              value={presetToUpdate}
              onChange={handlePresetSelectChange}
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              {presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => setIsAddingPreset(false)}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid}>
            {presetToUpdate ? "Update Preset" : "Create Preset"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

PresetActionDialog.propTypes = {
  label: PropTypes.string.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired, // This prop should be provided to handle the update of the selected preset.
  isValid: PropTypes.bool.isRequired,
  presets: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PresetActionDialog;
