import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//MATERIAL UI ICONS IMPORTS
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import MarkdownInfoModal from "../MarkdownInfoModal/MarkdownInfoModal";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

//REDUX IMPORTS
import { authLogout } from "../../store/slices/authSlice";
import { api, useChangeClientMutation } from "../../api/apiSlice";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { YS_DEFAULT_THEME } from "themes/YsTheme";
import useYsTheme from "hooks/useYsTheme"; // Import the useYsTheme hook

export const UserInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((state) => state.user.chosen_client);
  const client_list = useSelector((state) => state.user.client_list);
  const isClientAdmin = useSelector((state) => state.user.user.is_client_admin);
  const [changeClient] = useChangeClientMutation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Use the useYsTheme hook to get the theme dynamically
  const ysTheme = useYsTheme(); // Get the theme at the top level of the component
  const theme = createTheme(YS_DEFAULT_THEME(ysTheme));

  // State to force re-render after client change
  const [selectedClientId, setSelectedClientId] = useState(client?.id);

  const handleLogOut = () => {
    dispatch(api.util.resetApiState());
    dispatch(authLogout());
  };

  const handleClientChange = (client_id) => {
    changeClient({ new_client_id: client_id }).then(() => {
      setSelectedClientId(client_id); // Update the client ID and trigger a re-render
    });
  };

  // Define menu items
  const menuItems = [
    ...(Array.isArray(client_list)
      ? client_list.map((cl) => {
          return {
            text: cl.display_name,
            disabled: cl.id === client.id,
            onClick: () => handleClientChange(cl.id),
            icon: <AccountCircleIcon />,
          };
        })
      : []),
    <Divider key={"divider1"}></Divider>,
    {
      text: t("general.settings"),
      onClick: () => navigate("/app/user"),
      icon: <SettingsIcon></SettingsIcon>,
    },
    {
      text: t("general.qanda"),
      onClick: () => navigate("/app/qanda"),
      icon: <QuestionAnswerRoundedIcon></QuestionAnswerRoundedIcon>,
    },
    ...(isClientAdmin
      ? [
          {
            text: t("general.usermanagement"),
            onClick: () => navigate("/app/usermanagement"),
            icon: <QuestionAnswerRoundedIcon></QuestionAnswerRoundedIcon>,
          },
        ]
      : []),
    {
      text: t("general.logout"),
      onClick: () => handleLogOut(),
      icon: <ExitToAppIcon></ExitToAppIcon>,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 0.2,
          display: "flex",
          height: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          color: "primary.contrastText",
        }}
      >
        {!isMobile && (
          <>
            <Tooltip title={t("client.currency")}>
              <Typography variant="h6" sx={{ mr: 1 }}>
                {client?.client_info_fa?.currency}
              </Typography>
            </Tooltip>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "primary.contrastText",
                opacity: 0.6,
                height: "70%",
                alignSelf: "center",
              }}
            />
          </>
        )}
        <MarkdownInfoModal
          icon={
            <Tooltip title={t("client.clientInfo")}>
              <InfoIcon sx={{ color: "primary.contrastText" }} />
            </Tooltip>
          }
          markdownContent={client?.client_info_fa?.description}
        ></MarkdownInfoModal>

        <MarkdownInfoModal
          icon={
            <Tooltip title={t("client.contactInfo")}>
              <ContactSupportIcon sx={{ color: "primary.contrastText" }} />
            </Tooltip>
          }
          markdownContent={client?.client_info_fa?.contact_us}
        ></MarkdownInfoModal>

        {client?.logo_text && !isMobile && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "primary.contrastText",
                opacity: 0.6,
                height: "70%",
                alignSelf: "center",
              }}
            />
            <Typography variant="h6" ml={1}>
              {client?.logo_text}
            </Typography>
          </>
        )}
        <DropDownMenu
          menuId={"user-menu"}
          icon={
            <Tooltip title={client?.display_name || ""}>
              <AccountCircleIcon sx={{ color: "primary.contrastText" }} />
            </Tooltip>
          }
          menuItems={menuItems}
        />
      </Box>
    </ThemeProvider>
  );
};
