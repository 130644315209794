import React from "react";
import {
  useCreatePeTransactionMutation,
  useUpdatePeTransactionMutation,
  useDeletePeTransactionMutation,
  useGetPeDocumentTransactionsQuery,
} from "api/apiSlice"; // Replace 'your-api-slice' with the actual name of your RTK Query API slice
import EditableTransactionCard from "./EditableTransactionCard";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ConfirmActionDialog from "components/Dialogs/ConfirmActionDialog/ConfirmActionDialog";
import { useParams } from "react-router-dom";
import LoadingButton from "components/Buttons/LoadingButton";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
export const PeTransactions = ({
  currentActionDetails,
  handleSubmit,
  onAccept,
  isLoading,
  handleCloseConfirmDialog,
}) => {
  const { t } = useTranslation();
  const { documentId } = useParams();
  const transactionQuery = useGetPeDocumentTransactionsQuery({ documentId });
  const [createPeTransaction, createPeTransactionMutationState] =
    useCreatePeTransactionMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleAdd = () => {
    createPeTransaction({ data: { document: documentId } })
      .unwrap()
      .then((res) => {
        enqueueSnackbar("Transaction created successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Unable to create this transaction", {
          variant: "error",
        });
      });
  };

  return (
    <RTKQueryWrapper query={transactionQuery}>
      {(data) => {
        return (
          <Paper
            sx={{
              display: "flex",
              width: { xs: "100%", md: "30vw" },
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "30vw", p: 1 }}>
                <Toolbar sx={{ bgcolor: "primary.main", borderRadius: 1 }}>
                  <Button variant="contained" color="info" onClick={handleAdd}>
                    {t("documentProcessing.actions.addTransaction")}
                  </Button>
                </Toolbar>
                <Stack direction="column" spacing={1}>
                  {data.primary_transactions.map((item, index) => {
                    if (item.status === "new") {
                      return (
                        <EditableTransactionCard
                          transactionData={item}
                          formSupplementData={data.transaction_options}
                          key={index}
                          id={item.uuid}
                        />
                      );
                    }
                    return (
                      <Card key={item.transaction_id}>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body2">
                            {item.description}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="small">{t("")}</Button>
                        </CardActions>
                      </Card>
                    );
                  })}
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                width: "100%",
                p: 1,
              }}
            >
              <LoadingButton
                disabled={!data?.primary_transactions.some((t) => !t.processed)}
                variant="contained"
                color="success"
                onClick={handleSubmit}
                loading={isLoading}
              >
                {t("general.submit")}
              </LoadingButton>
              <ConfirmActionDialog
                open={Boolean(currentActionDetails)}
                text={t(
                  `documentProcessing.actions.submitTransactions.dialog.text`
                )}
                title={t(
                  `documentProcessing.actions.submitTransactions.dailog.title`
                )}
                onAccept={onAccept}
                onClose={handleCloseConfirmDialog}
                onDecline={handleCloseConfirmDialog}
              />
            </Box>
          </Paper>
        );
      }}
    </RTKQueryWrapper>
  );
};

export default PeTransactions;
