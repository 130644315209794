import { KEEP_UNUSED_DATA as keepUnusedDataFor } from "constants";
export const performanceEndpoints = (builder) => {
  return {
    getReturnBoxes: builder.query({
      query: ({ endDate, startDate, boxes }) => ({
        url: `fadata/standardComponents/returnBoxes/?endDate=${endDate}&startDate=${startDate}&boxes=${boxes}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_RETURN_BOXES" }],
    }),
    getPerformanceTextBoxes: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/performanceTextBoxes/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_TEXT_BOXES" }],
    }),
    getPerformanceTimeSeriesChart: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/performanceTimeSeriesChart/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_TIMESERIES_CHART" }],
    }),
    getPerformancePeriodBarChart: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/performancePeriodBarChart/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_PERIOD_BAR_CHART" }],
    }),
    getPerformanceDrilldown: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/performanceDrilldown/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_DRILLDOWN" }],
    }),
    getPerformanceContribution: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/performanceContribution/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_CONTRIBUTION" }],
    }),
    getPerformanceTable: builder.query({
      query: ({ endDate, startDate, grouping, group_code }) => {
        const params = new URLSearchParams();

        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (grouping) params.append("grouping", grouping);
        if (group_code) params.append("group_code", group_code);

        return {
          url: `fadata/standardComponents/performanceTable/?${params}`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "OnCalculate", id: "PERFORMANCE_TABLE" }],
    }),
  };
};
