import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useGetClientUserDetailsQuery } from 'api/apiSlice';
import { useTranslation } from 'react-i18next';

const UserLoginModal = ({ open, handleClose, userId }) => {
  const { t } = useTranslation();

  const {
    data: userDetails,
    error,
    isLoading,
    refetch,
    ...userDetailsQuery
  } = useGetClientUserDetailsQuery({ id: userId }, { skip: !userId });

  if (!userId) return null;

  if (isLoading) return <div>{t("userManagement.main.loading")}</div>;
  if (error) return <div>{t("userManagement.main.loginError")}</div>;

  // Helper function to format datetime
  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, '0'); // hh
    const minutes = date.getMinutes().toString().padStart(2, '0'); // mm
    const day = date.getDate().toString().padStart(2, '0'); // dd
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // MM
    const year = date.getFullYear(); // yyyy
    return `${hours}:${minutes} ${day}.${month}.${year}`;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: 'white',
          margin: 'auto',
          marginTop: '10%',
          width: '50%',
        }}
      >
        <Typography variant="h6">{t("userManagement.main.loginTitle")}</Typography>
        {userDetails?.login_times?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("userManagement.main.loginTime")}</TableCell>
                <TableCell>{t("userManagement.main.client")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userDetails.login_times.map((login, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDateTime(login.datetime)}</TableCell>
                  <TableCell>{login.client}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>{t("userManagement.main.nologins")}</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default UserLoginModal;
