import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
// Material UI IMPORTS
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { CardActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = "MarkdownInfoModal";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  cardActions: `${PREFIX}-cardActions`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [`& .${classes.card}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    height: "80vh",
    maxWidth: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.cardContent}`]: {
    flexGrow: 1,
    height: "90%",
    width: "100%",
    overflowY: "scroll",
  },

  [`& .${classes.cardActions}`]: {
    flexGrow: 0.1,
  },
}));

function MarkdownInfoModal(props) {
  const { icon, markdownContent } = props;
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const trimmedDescription = markdownContent?.slice(1, -1);
  const description = trimmedDescription?.replace(/\\n/g, "  \n");
  return markdownContent ? (
    <React.Fragment>
      <IconButton
        sx={{ color: "secondary.contrastText" }}
        onClick={handleOpen}
        size="large"
      >
        {icon ? icon : <InfoIcon />}
      </IconButton>
      <StyledModal
        className={classes.root}
        open={openModal}
        onClose={handleClose}
        disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant="outlined" onClick={handleClose}>
              {t("general.close")}
            </Button>
          </CardActions>
        </Card>
      </StyledModal>
    </React.Fragment>
  ) : null;
}
MarkdownInfoModal.propTypes = {
  icon: PropTypes.node,
  markdownContent: PropTypes.string,
};

export default MarkdownInfoModal;
