import { createSlice } from "@reduxjs/toolkit";
import { api } from "api/apiSlice";
import { authLogout } from "./authSlice";

const faDataSlice = createSlice({
  name: "faData",
  initialState: {
    positionsData: null,
    transactionOptions: null,
    error: null,
    lazyTrigger: 0,
  },
  reducers: {
    clearData: (state) => {
      state.positionsData = null;
      state.positionsData = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    lazyTrigger: (state, action) => {
      state.lazyTrigger = state.lazyTrigger + 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogout, (state) => {
        state.positionsData = null;
        state.transactionOptions = null;
      })
      .addMatcher(
        api.endpoints.getPositionsData.matchFulfilled,
        (state, action) => {
          state.positionsData = action.payload;
        }
      )
      .addMatcher(
        api.endpoints.getTransactionsOptions.matchFulfilled,
        (state, action) => {
          state.transactionOptions = action.payload;
        }
      );
  },
});

export const { clearData, clearError, lazyTrigger } = faDataSlice.actions;
export default faDataSlice.reducer;
