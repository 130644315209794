import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  error: null,
  loading: false,
  status: "",
};

const passwordChangeStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const passwordChangeSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    status: action.status,
  });
};

const passwordChangeFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const passwordResetStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const passwordResetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
  });
};

const passwordResetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};
const passwordResetConfirmStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const passwordResetConfirmSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    response: action.data,
  });
};

const passwordResetConfirmFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PASS_CHANGE_START:
      return passwordChangeStart(state, action);
    case actionTypes.PASS_CHANGE_SUCCESS:
      return passwordChangeSuccess(state, action);
    case actionTypes.PASS_CHANGE_FAIL:
      return passwordChangeFail(state, action);
    case actionTypes.PASS_RESET_START:
      return passwordResetStart(state, action);
    case actionTypes.PASS_RESET_SUCCESS:
      return passwordResetSuccess(state, action);
    case actionTypes.PASS_RESET_FAIL:
      return passwordResetFail(state, action);
    case actionTypes.PASS_RESET_CONFIRM_START:
      return passwordResetConfirmStart(state, action);
    case actionTypes.PASS_RESET_CONFIRM_SUCCESS:
      return passwordResetConfirmSuccess(state, action);
    case actionTypes.PASS_RESET_CONFIRM_FAIL:
      return passwordResetConfirmFail(state, action);
    default:
      return state;
  }
};

export default reducer;
