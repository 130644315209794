import React, { memo } from "react";
import Paper from "@mui/material/Paper";
const PDFDisplay = memo(({ src, sx, ...props }) => {
  return (
    <Paper sx={{ flexGrow: 1, p: 1, ...sx }}>
      <iframe {...props} src={src} width="100%" height={"100%"}></iframe>
    </Paper>
  );
});

export default PDFDisplay;
