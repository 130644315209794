import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { nb } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';

import { useGetDataQualityCheckByIdQuery, useRunDataQualityPerformedCheckQuery } from 'api/apiSlice';
import CheckResultDisplay from 'components/HealthCheck/CheckResultDisplay';

const CheckResult = () => {
  const { id } = useParams();  // Get the check ID from the URL
  const { data, error, isLoading } = useGetDataQualityCheckByIdQuery(id);  // Fetch the original check result
  const [selectedDate, setSelectedDate] = useState(new Date());  // State for the date picker
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [parameters, setParameters] = useState({});  // State for the check parameters
  const [parameterValues, setParameterValues] = useState({});  // State for parameter input values
  const [triggerCheck, setTriggerCheck] = useState(false);  // Track when to trigger re-run check
  const [runResult, setRunResult] = useState(null);  // Store the rerun result
  const [checkDescription, setCheckDescription] = useState('');  // Store the check description

  const { data: rerunCheckResult, isFetching: isRunning, refetch } = useRunDataQualityPerformedCheckQuery({
    id,
    endDate: format(selectedDate, 'yyyy-MM-dd'),  // Format date for the API call
    parameters: parameterValues,  // Send the selected parameter values
  }, {
    skip: !triggerCheck  // Only run if triggerCheck is true
  });

  // Populate parameters and initial result when the component loads
  useEffect(() => {
    if (data) {
      // Assuming data provides the selectedCheck ID
      setSelectedCheck(data.data_quality_check_details?.id || null);
  
      // Set the check description
      setCheckDescription(data.check_description || 'No description available');

      // Prepopulate parameters
      let mergedParameters = {}; // Declare mergedParameters once
  
      if (data.parameters) {

  
        const previousValues = data.parameters; // The actual values from the backend
        const parameterMeta = data.data_quality_check?.parameters || {}; // The metadata for the parameters
  
        Object.keys(parameterMeta).forEach(paramName => {
          mergedParameters[paramName] = {
            ...parameterMeta[paramName], // Include the metadata (type, label, etc.)
            value: previousValues[paramName] || parameterMeta[paramName]?.default || '', // Use the existing values or default
          };
        });
  
  
        setParameters(mergedParameters); // Set the merged parameters
      }
  
      // Set runResult based on the existing data
      setRunResult({
        check_name: data.check_name,
        check_passed: data.check_passed,
        result_details: data.json_data,
        raw_result_details: data.raw_data,
        last_updated: data.last_updated,
      });

    }
  }, [data]);
  


  // Update the result when the check is re-run
  useEffect(() => {
    if (rerunCheckResult) {
      setRunResult({
        check_name: rerunCheckResult.check_name,
        check_passed: rerunCheckResult.check_passed,
        result_details: rerunCheckResult.json_data,
        raw_result_details: rerunCheckResult.raw_data,
        last_updated: rerunCheckResult.last_updated,
        
      });
      setTriggerCheck(false);  // Reset trigger after running the check
    }
  }, [rerunCheckResult]);

  const handleParameterChange = (name, value) => {
    setParameterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const runCheck = () => {
    if (!id || !selectedDate) return;

    // Reset the result when running a new check
    setRunResult(null);
    setTriggerCheck(true);  // Trigger the check re-run
  };

  const renderParameterInput = (paramName, paramMeta) => {
    const value = paramMeta?.value || ''; // Get the actual value

    switch (paramMeta.type) {
      case 'date':
        return (
          <DatePicker
            label={paramMeta.label || paramName}
            value={value ? new Date(value) : null}
            onChange={(newValue) => handleParameterChange(paramName, newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        );
      case 'string':
        return (
          <TextField
            label={paramMeta.label || paramName}
            value={value}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      case 'number':
        return (
          <TextField
            label={paramMeta.label || paramName}
            type="number"
            value={value}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      default:
        return null;
    }
  };


  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching check data</div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
      <Box sx={{ height: "83vh", width: "100%", p: 2 }}>
        <h1>Check Result for ID: {id}</h1>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </Grid>

            {Object.keys(parameters).length > 0 && (
              <Box sx={{ mt: 2, maxWidth: 500 }}>
                <h3>Check Parameters</h3>
                {Object.keys(parameters).map((paramName) => (
                  <Box sx={{ mb: 2 }} key={paramName}>
                    {renderParameterInput(paramName, parameters[paramName])}
                  </Box>
                ))}
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={runCheck}
              disabled={isRunning}
              sx={{ mt: 2 }}
            >
              {isRunning ? 'Re-running...' : 'Re-run Check'}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                p: 2,
                border: '1px solid grey',
                borderRadius: '4px',
                backgroundColor: '#f5f5f5',
                height: '100%',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3>Check Description</h3>
              <Box sx={{ flexGrow: 1 }}>
                <p>{checkDescription}</p>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Display the result */}
        <CheckResultDisplay
          runResult={runResult}
          checks={[{ id, name: data.check_name }]}
          selectedCheck={id}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CheckResult;
