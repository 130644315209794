import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useSnackbar } from "notistack";
import { formatBytes } from "utils/byteToMegaByte";
import { useTranslation } from "react-i18next";
const DownloaderNew = ({
  data,
  remove,
  endPoint = "fadata/excel_files",
  headers,
}) => {
  const token = useSelector((state) => state.auth.token);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    let is_mounted = true;
    const source = axios.CancelToken.source();
    if (is_mounted) {
      const url = `/${endPoint}/?${
        data.requestParams
          ? data.requestParams.reduce(
              (state, next) => `${state}&${next[0]}=${next[1]}`,
              ""
            )
          : ""
      }`;
      const method = "GET";
      const body = null;
      const defaultHeaders = {
        Authorization: "Token " + token,
      };

      axios({
        method: method,
        baseURL: import.meta.env.VITE_API_URL,
        url: url,
        data: body,
        responseType: "blob",
        headers: headers ? headers : defaultHeaders,
        cancelToken: source.token,
        onDownloadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          const total = progressEvent?.currentTarget?.response?.size || 1;
          const percentage = Math.floor((loaded * 100) / total);
          setDownloadInfo({
            progress: percentage,
            loaded: loaded,
            total,
            completed: percentage === 100,
          });
        },
      })
        .then(function (response) {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", data.fileName);
          document.body.appendChild(link);
          link.click();

          setDownloadInfo((info) => ({
            ...info,
            completed: true,
          }));

          setTimeout(() => {
            remove();
          }, 4000);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            enqueueSnackbar(`Download of ${data?.fileName} failed!`, {
              variant: "error",
            });
          }
        });
    }

    return () => {
      is_mounted = false;
      source.cancel("axios request cancelled");
    };
  }, []);

  return (
    <div>
      {downloadInfo.loaded > 0 && (
        <span
          style={{
            padding: "0.5rem",
            marginRight: "0.5rem",
            fontStyle: "bold",
          }}
        >
          {formatBytes(downloadInfo.loaded)}
        </span>
      )}

      {downloadInfo.loaded === 0 && <>Initializing...</>}
      {downloadInfo.completed &&
        enqueueSnackbar(
          t(`notisstack.downloadSuccess`, { value: data?.fileName }),
          {
            variant: "success",
          }
        )}
    </div>
  );
};
export default DownloaderNew;
