import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  Autocomplete,
  TextField,
  Toolbar,
} from "@mui/material";
import CurrencyCard from "./CurrencyCard";
import { CurrencyExposureChart } from "./CurrencyExposureChart";
import { CurrencyExposureChartUnhedged } from "./CurrencyExposureChartUnhedged";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useGetCurrenciesExposureDetailQuery } from "api/apiSlice";
import { DataGridBasic } from "components/Tables/DataGridTable";
import { useTranslation } from "react-i18next";

export function CurrencyOverview() {
  const date = useSelector((state) => state.user.endDate);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = React.useState({ value: "", label: "Sort by" });
  const currencyExposureDetailsQuery = useGetCurrenciesExposureDetailQuery(
    { endDate: date },
    {
      skip: !date,
    }
  );
  const sortingOptions = [
    { value: "name", label: t("general.name") },
    { value: "marketValueLocal", label: t("financeTerms.marketValueLocal") },
    { value: "marketValue", label: t("financeTerms.marketValue") },
    { value: "shareOfTotal", label: t("financeTerms.shareOfTotal") },
    { value: "marketFxRate", label: t("financeTerms.marketFXRate") },
    { value: "hedges", label: t("financeTerms.hedges") },
    { value: "underlying", label: t("financeTerms.underlying") },
  ];
  return (
    <Grid container justifyContent={"center"} width={"100%"}>
      <Grid item p={2} xs={12} lg={6} minHeight={"500px"}>
        <CurrencyExposureChart date={date} />
      </Grid>
      <Grid item p={2} xs={12} lg={6}>
        <CurrencyExposureChartUnhedged date={date} minHeight={"500px"} />
      </Grid>

      <Grid item p={2} xs={12}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} px={1}>
            <Toolbar
              sx={{
                width: "100%",
                borderRadius: 1,
                boxShadow: 6,
              }}
            >
              <Autocomplete
                sx={{
                  width: 300,
                }}
                options={sortingOptions}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value?.value;
                }}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option}>
                    {option.label}
                  </MenuItem>
                )}
                variant="outlined"
                size="small"
                onChange={(e, value) => setSortBy(value)}
                renderInput={(params) => (
                  <TextField label={t("general.sortBy")} {...params} />
                )}
              />
            </Toolbar>
          </Grid>
          <RTKQueryWrapper query={currencyExposureDetailsQuery}>
            {(data) => {
              const { currencies } = data;
              const sortedCurrencies = currencies.sort((a, b) => {
                if (!sortBy) return 0; // If no sort key is provided, don't sort.

                const aValue = a[sortBy?.value];
                const bValue = b[sortBy?.value];

                if (typeof aValue === "number" && typeof bValue === "number") {
                  return bValue - aValue;
                } else {
                  return String(aValue).localeCompare(String(bValue));
                }
              });
              return sortedCurrencies.map((currency) => {
                if (selectedCurrency?.code === currency.code) {
                  return null;
                }
                return (
                  <Grid
                    key={currency?.code}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    padding={1}
                  >
                    <CurrencyCard
                      selected={selectedCurrency}
                      data={currency}
                      setSelectedCurrency={setSelectedCurrency}
                    />
                  </Grid>
                );
              });
            }}
          </RTKQueryWrapper>
        </Grid>
      </Grid>
      {selectedCurrency && (
        <Fragment>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} p={2}>
            <CurrencyCard
              data={selectedCurrency}
              setSelectedCurrency={() => setSelectedCurrency(null)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 500, padding: 2 }}>
              <DataGridBasic
                showToolbar={true}
                Title={() => (
                  <Typography variant="h6" color={"primary.main"}>{`${
                    selectedCurrency.name
                  } ${t("financeTerms.positions")}`}</Typography>
                )}
                data={selectedCurrency?.positions}
              />
            </Box>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}
