import React from "react";
import { ICONS } from "../../../assets/Icons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  useUpdatePeDocumentMutation,
  useImportCreatedPeTransactionsMutation,
} from "api/apiSlice";
import { Toolbar } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { PeTransactions } from "./PeTransactions";
import { useTranslation } from "react-i18next";
import PDFDisplay from "components/PDFDisplay/PDFDisplay";
export const Document = () => {
  const { documentId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  const [updateDocument, { isLoading }] = useUpdatePeDocumentMutation();
  const [submitTransactions] = useImportCreatedPeTransactionsMutation();
  const [displayEmailBody, setDisplayEmailBody] = React.useState(true);
  const [displayPDF, setDisplayPDF] = React.useState(true);
  const [currentActionDetails, setCurrentActionDetails] = React.useState(false);

  const handleDocumentNotTransaction = () => {
    handleOpenConfirmDialog({ action: "no_transactions", id: documentId });
  };
  const handleSubmit = (data) => {
    handleOpenConfirmDialog({ action: "submit", id: documentId, data: data });
  };
  const onAccept = () => {
    const { action, id, data, resolve } = currentActionDetails;
    if (action === "no_transactions") {
      updateDocument({ id, data }).then(resolve);
    } else if (action === "submit") {
      submitTransactions({ documentId }).then(resolve);
    }
    // Reset and close dialog after action
    setCurrentActionDetails(null);
  };
  const handleOpenConfirmDialog = ({ action, id, data }) => {

    return new Promise((resolve, reject) => {
      setCurrentActionDetails({ action, id, data, resolve, reject });
    });
  };
  const handleCloseConfirmDialog = () => {
    if (currentActionDetails && currentActionDetails.reject) {
      currentActionDetails.reject(new Error("Action cancelled"));
    }
    setCurrentActionDetails(null);
  };

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{ width: "100%", bgcolor: "secondary.main" }}
      >
        <Tooltip title="Toggle PDF display">
          <IconButton
            sx={{
              opacity: displayPDF ? 1 : 0.5,
              color: "secondary.contrastText",
            }}
            onClick={() => setDisplayPDF((oldState) => !oldState)}
          >
            {ICONS.pdf()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Toggle email body display">
          <IconButton
            sx={{
              color: "secondary.contrastText",
              opacity: displayEmailBody ? 1 : 0.5,
            }}
            onClick={() => setDisplayEmailBody((oldState) => !oldState)}
          >
            {displayEmailBody ? ICONS.visibilityOn() : ICONS.visibilityOff()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Set as `Not Transaction`">
          <IconButton
            sx={{
              color: "error.main",
              marginLeft: "auto",
            }}
            onClick={() => handleDocumentNotTransaction()}
          >
            {ICONS.delete()}
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Stack
        direction={{ md: "row", xs: "column" }}
        width={"100%"}
        height={(theme) => `calc(100% - ${theme.mixins.toolbar.minHeight}px)`}
      >
        {displayPDF && <PDFDisplay src={location.state?.data?.attachment} />}
        {displayEmailBody && (
          <Paper sx={{ flexGrow: 0.6, p: 1 }}>
            <iframe
              srcDoc={location.state?.data?.content}
              height="100%"
              width="100%"
            ></iframe>
          </Paper>
        )}
        <Box sx={{ m: 1, display: "flex", maxHeight: "100%" }}>
          <PeTransactions
            currentActionDetails={currentActionDetails}
            onAccept={onAccept}
            isLoading={isLoading}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Stack>
    </>
  );
};

export default Document;
