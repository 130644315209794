import React from "react";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
export const BasicCustomToolbar = ({ Title, ...props }) => {
  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      {Title && <Title props={props}></Title>}
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
};

BasicCustomToolbar.propTypes = {};

export default BasicCustomToolbar;
