import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const FormInputNumber = ({
  classes,
  name,
  control,
  label,
  callback,
  size,
  isRequired,
  maxLength,
  minValue,
  maxValue,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired && {
          value: isRequired,
          message: `${label} is required`,
        },
        maxLength: maxLength && {
          value: maxLength,
          message: `${label} can't be longer than ${maxLength} characters`,
        },
        min: minValue && {
          value: minValue,
          message: `${label} value can't be less than ${minValue}`,
        },
        max: maxValue && {
          value: maxValue,
          message: `${label} value can't be more than ${maxValue}`,
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const handleChange = (e) => {
          const value = e.target.value === "" ? "" : Number(e.target.value);
          field.onChange(value);
          if (callback) {
            callback(e);
          }
        };
        return (
          <TextField
            {...field}
            onChange={handleChange}
            className={classes?.formInput}
            variant="outlined"
            disabled={disabled}
            type="number"
            size={size ? size : "small"}
            fullWidth
            required={isRequired}
            error={!!error}
            helperText={error?.message}
            label={label}
            inputProps={{
              max: maxValue,
              min: minValue,
              maxLength: maxLength,
            }} // Setting the native attributes
            autoComplete="off"
          />
        );
      }}
    />
  );
};

FormInputNumber.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool,
};

export default FormInputNumber;
