import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CardContent from "@mui/material/CardContent";
import { CardHeader, Collapse, Divider, Box } from "@mui/material";
import { formatCurrency, formatValues } from "../../../utils/functions";
import { useTranslation } from "react-i18next";
import Flag from "components/Flag/Flag";
const CurrencyCard = ({ data, onClick, clientCurrency, selected }) => {
  const { t } = useTranslation();
  const { bal_base, bal_loc, cur_rate, currencyCode } = data;
  return (
    <Card
      sx={{
        width: "100%",
        cursor: "pointer",
        backgroundColor: "transparent",
        maxWidth: "300px",
      }}
      onClick={() =>
        onClick(selected.currencyCode === currencyCode ? null : data)
      }
      elevation={8}
    >
      <CardHeader
        sx={{
          p: 1,
          textAlign: "center",
          bgcolor: "primary.main",
          color: "primary.contrastText",
        }}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Flag currencyCode={currencyCode} />
            <Typography variant="h5">{currencyCode}</Typography>
          </Box>
        }
        titleTypographyProps={{
          variant: "h6",
          sx:
            selected?.currencyCode === currencyCode
              ? { color: "info.contrastText", backgroundColor: "info.main" }
              : {
                  color: "primary.contrastText",
                  backgroundColor: "primary.main",
                },
        }}
      />
      <Collapse in={selected.currencyCode === currencyCode || !selected}>
        <CardContent
          sx={{ p: 1, bgcolor: "info.main", color: "info.contrastText" }}
        >
          <Grid
            container
            color="palette.primary.main"
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle">
                {t("financeTerms.accountBalance", {
                  currency: "",
                })}
              </Typography>
              <Typography variant="h6">
                {formatCurrency(bal_loc, currencyCode, 2)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal">
                <Typography variant="caption">
                  {`${currencyCode}/${clientCurrency} ` + cur_rate?.toFixed(2)}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle">{`${t(
                "financeTerms.marketValue"
              )} ${clientCurrency}`}</Typography>
              <Typography variant="h6">
                {formatValues(bal_base, "millions", "", 1, 1)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};
CurrencyCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default CurrencyCard;
