export const localGridReducer = (state, action) => {
  switch (action.type) {
    case "setActivePreset": {
      return {
        ...state,
        newPresetLabel: "",
        activePreset: action.presetId,
      };
    }
    case "setnewPresetLabel": {
      return {
        ...state,
        newPresetLabel: action.label,
      };
    }

    case "togglePopper": {
      return {
        ...state,
        isMenuOpened: !state.isMenuOpened,
        menuAnchorEl: action.element,
      };
    }

    case "closePopper": {
      return {
        ...state,
        isMenuOpened: false,
      };
    }

    default: {
      return state;
    }
  }
};
